import store from '@/store';

export default (to, from, next) => {
    const has_sim_type_to_purchase = store.getters.has_sim_type_to_purchase;

    if (!has_sim_type_to_purchase) store.commit('RESET_SIM');

    return {
        allow: has_sim_type_to_purchase,
        redirect: { path: from?.query?.flow == 'order-placement'
            ? 'order-placement'
            : 'sim-purchase'
        },
    }
};
