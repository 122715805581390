import { firestoreAction } from 'vuexfire'

import { db } from '@/utils/firebase.js'

const COLLECTION_OFFERS = 'offers'

const offerDetails = (offer, offer_plan_calculated_services) => {
    return {
        'Discount': `${offer.discount * 100} %`,
        ...offer_plan_calculated_services,
    }
}

export default {
    state: {
        offers: [],
    },

    getters: {
        all_offers: (state, getters) => state.offers
            .filter( offer => offer.planId !== getters.current_plan?.id && getters.getPlanById(offer.planId) )
            .map( offer => {
                const plan = getters.getPlanById(offer.planId)
                const plan_services = plan.services
                return {...offer, id: offer.id, plan, details: offerDetails(offer, getters.calculate_services(plan_services))}
            }),

        getOfferById: (state, getters) => id => getters.offers.find( offer => offer.id == id ),
    },

    actions: {
        BindOffers: firestoreAction(({ bindFirestoreRef, state }) => {
            return bindFirestoreRef('offers', db.collection(COLLECTION_OFFERS))
        }),

        UnbindOffers: firestoreAction(({ unbindFirestoreRef }) => {
            return unbindFirestoreRef('offers')
        }),

        /* BindFirebaseData({dispatch}) {
            dispatch('BindOffers')
        }, */
    },
    mutations: {
    },
}