import axiosIntents from '@/utils/axios-intents'
import store from '@/store'
import StoreCache from '@/utils/StoreCache.js'
import ordinalSuffixes from '@/helpers/ordinal-suffixes';
import i18n from '@/i18n'

import { db } from '@/utils/firebase.js';
import { firestoreAction } from 'vuexfire';

const cached = {
    catalog_services: new StoreCache(30 * 60 * 1000)
}

const SYNCHRONISATIONS_PATH = 'synchronisations'

let debounce_timeout = null
const DEBOUNCE_TIME = 1500

const clearDebounce = () => {
    if (debounce_timeout !== null) {
        clearTimeout(debounce_timeout)

        debounce_timeout = null
    }
}

const state = {
    catalog_services: [],
    is_services_loaded: false,
    get_catalog_services_trigger: null,
    get_catalog_services_sld_trigger: null,
    is_catalog_services_request_done: false,
}

const getters = {
    is_services_loaded: state => state.is_services_loaded,
    is_catalog_services_request_done: state => state.is_catalog_services_request_done,

    all_services: state => state.catalog_services.sort((a, b) => a.CreatedAtNanos - b.CreatedAtNanos),
    has_services: state => Boolean(state.catalog_services.length),
    all_available_services: (state, getters) => getters.all_services.filter(
        (service) => 
                    service.Availabilities?.[0]?.Available && service.Characteristics &&  
                    !(
                        (service.Characteristics.IsBasePlan == 'false' || service.Characteristics.IsBasePlan == false || service.Characteristics.IsBasePlan == undefined) && 
                        (service.Characteristics.IsAddon == 'false' || service.Characteristics.IsAddon == false || service.Characteristics.IsAddon == undefined)
                    )
    ).sort((a, b) => a.Availabilities[0].Priority - b.Availabilities[0].Priority),

    all_available_plan_services: (state, getters) => getters.all_available_services.filter(service => (service.Characteristics.IsBasePlan == 'true' || service.Characteristics.IsBasePlan === true)),
    all_available_addon_services: (state, getters) => getters.all_available_services.filter(service => (service.Characteristics.IsAddon == 'true' || service.Characteristics.IsAddon === true)),

    all_prepay_services: (state, getters) => getters.all_services.filter((service) => service.ProductType === "prepaymobileAddon"),
    available_prepay_services: (state, getters) => getters.all_prepay_services.filter((service) => service.Availabilities?.[0]?.Available).sort((a, b) => a.Availabilities[0].Priority - b.Availabilities[0].Priority),
	available_recurring_prepay_services: (state, getters) => getters.available_prepay_services.filter((service) => service.Characteristics?.AllowAutoRenew  && service.Characteristics?.AllowAutoRenew != 'false'),
	available_no_recurring_prepay_services: (state, getters) => getters.available_prepay_services.filter((service) => !service.Characteristics?.AllowAutoRenew || service.Characteristics?.AllowAutoRenew == 'false'),

    all_postpay_plans: (state, getters) => getters.all_services.filter((service) => service.ProductType === "postpaymobile"),
    available_postpay_plans: (state, getters) => getters.all_postpay_plans.filter((plan) => plan.Availabilities?.[0]?.Available).sort((a, b) => a.Availabilities[0].Priority - b.Availabilities[0].Priority),

    all_postpay_addons: (state, getters) => getters.all_services.filter((service) => service.ProductType === "postpaymobileAddon"),
	available_postpay_addons: (state, getters) => getters.all_postpay_addons.filter((addon) => addon.Availabilities?.[0]?.Available).sort((a, b) => a.Availabilities[0].Priority - b.Availabilities[0].Priority),

    current_product_type_services: (state, getters) => getters.is_account_postpay ? getters.all_postpay_plans : getters.all_prepay_services,
    current_product_type_available_services: (state, getters) => getters.is_account_postpay ? getters.available_postpay_plans : getters.available_prepay_services,
    current_product_service: (state, getters) => getters.current_product_type_services.find(service => service.UUID == getters.customer_current_product_catalog_uuid),

    get_catalog_services_trigger: state => state.get_catalog_services_trigger,
    get_catalog_services_sld_trigger: state => state.get_catalog_services_sld_trigger,
}

const mutations = {
    SAVE_CATALOG_SERVICES(state, catalog_services) {
        state.catalog_services = catalog_services
        state.is_services_loaded = true

        cached.catalog_services.fix()
    },

    RESET_CATALOG_SERVICES(state) {
        state.catalog_services = []
        state.is_services_loaded = false

        cached.catalog_services.reset()
    },

    SET_IS_CATALOG_SERVICES_LOADED(state, is_services_loaded) {
        state.is_services_loaded = is_services_loaded
    },

    SET_IS_CATALOG_SERVICES_REQUEST_DONE(state, is_catalog_services_request_done) {
        state.is_catalog_services_request_done = is_catalog_services_request_done
    },
}

const actions = {
    BindGetCatalogServicesTrigger: firestoreAction(({ bindFirestoreRef }) => {
        const ref = db.collection(SYNCHRONISATIONS_PATH).doc('catalog')

        return bindFirestoreRef('get_catalog_services_trigger', ref).catch(error => {
            console.error('BindGetCatalogServicesTrigger error', error)
        })
	}),

    BindGetCatalogServicesSldTrigger: firestoreAction(({ bindFirestoreRef }) => {
        const ref = db.collection(SYNCHRONISATIONS_PATH).doc('sld')

        return bindFirestoreRef('get_catalog_services_sld_trigger', ref).catch(error => {
            console.error('BindGetCatalogServicesSldTrigger error', error)
        })
	}),

    GetCatalogServices({getters, commit},
        {   payload = { 
                ProductTypeList: ["mobileAddon"],
                SearchOptions: { PageSize: 1000 },
            },

            force = false
        } = {}
    ) {
        commit('SET_IS_CATALOG_SERVICES_REQUEST_DONE', false)

        if (!force && !cached.catalog_services.expired()) {
            commit('SET_IS_CATALOG_SERVICES_LOADED', true)

            return Promise.resolve(getters.all_available_services)
        }

        if (!force) {
            commit('SET_IS_CATALOG_SERVICES_LOADED', false)
        } else {
            if (getters.all_available_services.length) {
                commit('SET_IS_CATALOG_SERVICES_LOADED', true)
            } else {
                commit('SET_IS_CATALOG_SERVICES_LOADED', false)
            }
        }

        clearDebounce()

        return new Promise((resolve) => {
            const unwatch = store.watch((state, getters) => getters.is_catalog_services_request_done, is_catalog_services_request_done => {
                if (is_catalog_services_request_done) {
                    unwatch()

                    resolve(getters.all_available_services)
                }
            })
            
            debounce_timeout = setTimeout(() => {
                const ID = getters.current_account_uid
                const ProductInstanceUUID = getters.customer_current_product_uuid
                const ProductType = getters.accountType
    
                const castomer_request_payload = {
                    ...payload,
                    CustomerInfo: { ID },
                }
    
                if (ProductType && ProductType != 'Not Activated') {
                    castomer_request_payload.ProductInfo = { ProductType: `${ProductType}mobile` }
    
                    if (ProductInstanceUUID) {
                        castomer_request_payload.ProductInfo.ProductInstanceUUID = ProductInstanceUUID
                    }
                }
    
                const request_payload = ID ? castomer_request_payload : payload
                const request_params = ID ? { params: {} } : { params: { noauth: true } }
    
                axiosIntents.post('/catalog/v2/entries', request_payload, request_params).then(({data}) => {
                    if (!data) {
                        console.log("No data")

                        commit('RESET_CATALOG_SERVICES')

                        return
                    }

                    const current_entries = getters.all_services

                    const { Entries = [], Prices } = data

                    if (!current_entries.length && !Entries.length) {
                        console.log("No Entries")

                        return
                    }

                    let is_entries_updated = false

                    if (current_entries.length != Entries.length) {
                        is_entries_updated = true
                    }

                    if (Prices && Prices.length) {
                        Prices.forEach(price_item => {
                            const has_discount = price_item.DiscountedPrice < price_item.OrigianlPrice
                            const has_offer_credit = Boolean(price_item.CreditPrice)
                            const has_special_offers_to_show = price_item.AmountInPeriod?.length > 1

                            if (has_discount || has_offer_credit || has_special_offers_to_show) {
                                const entry = Entries.find(entry => entry.Availabilities?.[0]?.UUID == price_item.ProductID)
    
                                if (entry) {
                                    if (has_discount) {
                                        if (entry.Availabilities?.[0]) {
                                            entry.Availabilities[0].RetailCents = price_item.DiscountedPrice
                                        }

                                        entry.origianl_price = price_item.OrigianlPrice
                                        entry.discount = price_item.OrigianlPrice - price_item.DiscountedPrice
                                        entry.DiscountUUID = price_item.AppliedOfferID
                                        entry.total_price = price_item.DiscountedPrice
                                    }

                                    if (has_offer_credit) {
                                        entry.offer_credit = price_item.CreditPrice
                                    }

                                    if (has_special_offers_to_show) {
                                        entry.special_offers_to_show = price_item.AmountInPeriod.map((offers, index) => {
                                            let offer = ''
                                            let amount = ''

                                            if (offers.DiscountedAmount) {
                                                offer = i18n.t('price').toLowerCase()
                                                amount = ((price_item.OrigianlPrice - offers.DiscountedAmount) / 100).toFixed(2)
                                            } else {
                                                offer = i18n.t('credit').toLowerCase()
                                                amount = (offers.CreditAmount / 100).toFixed(2)
                                            }

                                            return {
                                                text: `${ordinalSuffixes(index + 1)} ${i18n.t('period').toLowerCase()} ${offer}:`,
                                                amount: `$${amount}`
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }

                    Entries.forEach(entry => {
                        entry.price_catalog_cents = entry.Availabilities?.[0]?.RetailCents
                        entry.total_price = entry.price_catalog_cents

                        if (!is_entries_updated) {
                            const current_entry = current_entries.find(current_entry => current_entry.UUID == entry.UUID)

                            if (
                                !current_entry
                                || current_entry.UpdatedAtNanos !== entry.UpdatedAtNanos
                                || current_entry.total_price !== entry.total_price
                                || current_entry.Availabilities?.[0]?.RetailCents !== entry.Availabilities?.[0]?.RetailCents
                                || current_entry.origianl_price !== entry.origianl_price
                                || current_entry.discount !== entry.discount
                                || current_entry.DiscountUUID !== entry.DiscountUUID
                                || current_entry.total_price !== entry.total_price
                                || (current_entry.special_offers_to_show && !entry.special_offers_to_show)
                                || (!current_entry.special_offers_to_show && entry.special_offers_to_show)
                                || (current_entry.special_offers_to_show && entry.special_offers_to_show && current_entry.special_offers_to_show.length != entry.special_offers_to_show.length)
                            ) {
                                is_entries_updated = true
                            } else if (current_entry.special_offers_to_show && entry.special_offers_to_show && current_entry.special_offers_to_show.length) {
                                for (let index = 0; index < current_entry.special_offers_to_show.length; index++) {
                                    const current_entry_special_offer = current_entry.special_offers_to_show[index]
                                    const entry_special_offer = entry.special_offers_to_show[index]

                                    if (current_entry_special_offer.text != entry_special_offer?.text || current_entry_special_offer.amount != entry_special_offer?.amount) {
                                        is_entries_updated = true

                                        break
                                    }
                                }
                            }
                        }
                    })

                    if (is_entries_updated) {
                        commit('SAVE_CATALOG_SERVICES', Entries)
                    }
                }).catch(error => {
                    console.log('getCatalogServicesRequest Error', error)
    
                    commit('RESET_CATALOG_SERVICES')
                }).finally(() => {
                    commit('SET_IS_CATALOG_SERVICES_LOADED', true)

                    commit('SET_IS_CATALOG_SERVICES_REQUEST_DONE', true)
    
                    debounce_timeout = null
                })
            }, DEBOUNCE_TIME)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}