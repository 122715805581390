import axiosIntents from '@/utils/axios-intents'
import { db } from '@/utils/firebase.js'

import i18n from '@/i18n';

import { isCardExpired } from '@/helpers/credit-card-helpers'

const unsubscribes = {}
const unsubscribeAll = () => {
    for (const uuid in unsubscribes) {
        unsubscribes[ uuid ]()
        delete unsubscribes[ uuid ]
    }
}

const noCurrentAccountUid = () => Promise.reject(new Error(i18n.t('current_account_not_specified')))

// для фиксации момента удаления карты по-умолчанию чтоб "насильно" установить другую
let is_payment_methods_default_removed = false
let last_payment_methods_count = 0



const state = {
    payment_methods: [],
    
    is_payment_methods_loading: false,
}

const getters = {
    payment_methods: state => state.payment_methods || [],
    
    payment_methods_cards: (state, getters) => {
        let payment_methods_cards = []

        const payment_methods = getters.payment_methods

        for (let i = 0, len = payment_methods.length; i < len; i++) {
            const payment_method = payment_methods[i]

            const is_default = payment_method.Rank == 1
            const cardId = payment_method.UUID
            const brand = payment_method.CardType
            const last4 = payment_method.CardLast4
            const token = payment_method.Token
            const expiry_month = payment_method.CardExpiryMonth
            const expiry_year = payment_method.CardExpiryYear
            const is_card_expired = isCardExpired(expiry_month, expiry_year)

            payment_methods_cards.push({ is_default, cardId, brand, last4, expiry_month, expiry_year, is_card_expired, token })
        }

        payment_methods_cards.sort((a, b) => b.is_default - a.is_default)

        return [...payment_methods_cards]
    },
    defaultPaymentCardId: (state, getters) => {
       return getters.payment_methods.find(({Rank}) => Rank == 1)?.UUID ?? ''
    },
    defaultPaymentCardLast4: (state, getters) => {
       return getters.payment_methods.find(({Rank}) => Rank == 1)?.CardLast4 ?? ''
    },

    has_payment_methods_cards: (state, getters) => (getters.is_account && getters.payment_methods.length > 0),
    has_payment_cards_or_parent_billing_routes: (state, getters) => (getters.has_payment_methods_cards || (getters.hasParentBillingRoutes && !getters.hasChildBillingRoutes)),

    is_payment_methods_loading: state => state.is_payment_methods_loading,
}

const mutations = {
    savePaymentMethods(state, payment_methods) {
        state.payment_methods = payment_methods
    },

    setPaymentMethodsLoading(state, loading) {
        state.is_payment_methods_loading = loading
    },

    resetPaymentMethods(state) {
        state.payment_methods = []

        unsubscribeAll()
    },
}

const actions = {
    setupIntentClientSecret({getters}) {
        console.log('setupIntentClientSecret')
        const uuid = getters.current_account_uid
        console.log('uuid', uuid)

        return uuid
            ? axiosIntents.get(`/customer/${ uuid }/setup-payment-method`)
                .then(({result}) => Promise.resolve(result.SetupIntentClientSecret))
                .catch(error => Promise.reject(error))
            : noCurrentAccountUid()
    },

    subscribePaymentMethods({getters, commit, dispatch}) {
        console.log('subscribePaymentMethods')
        const uuid = getters.current_account_uid
        console.log('uuid', uuid)

        if (uuid) {
            if (!(uuid in unsubscribes)) {
                unsubscribeAll()

                last_payment_methods_count = 1

                commit('setPaymentMethodsLoading', true)

                return new Promise(resolve => {
                    unsubscribes[uuid] = db.collection(`/customers/${ uuid }/payment_methods`).onSnapshot(collection => {
                        // console.group(`/customers/${ uuid }/payment_methods`)
    
                        commit('setPaymentMethodsLoading', false)
    
                        let payment_methods = []
                        let payment_method_default_exists = false
    
                        collection.forEach((doc) => {
                            const payment_method = doc.data()
    
                            if (!payment_method.DeletedAtNanos) {
                                payment_methods.push(payment_method)
                                // console.log(payment_method.CardLast4, ' => ', payment_method.Rank)
                                
                                if (payment_method.Rank == 1) {
                                    payment_method_default_exists = true
                                }
                            }
                        })
    
                        const payment_methods_count = payment_methods.length
                        const is_payment_method_added = payment_methods_count && !last_payment_methods_count
    
                        last_payment_methods_count = payment_methods_count
    
                        // console.log('payment_method_default_exists', payment_method_default_exists)
                        // console.log('is_payment_methods_default_removed', is_payment_methods_default_removed)
                        // console.log('payment_methods', payment_methods.length, payment_methods)
    
                        commit('savePaymentMethods', payment_methods)
    
                        // console.groupEnd()
    
                        payment_method_default_exists || !payment_methods_count || !(is_payment_method_added || is_payment_methods_default_removed)
                            ? resolve(getters.payment_methods)
                            : dispatch('setDefaultPaymentMethod', payment_methods[0].UUID)
                                .then(() => resolve(getters.payment_methods))
                                .catch(() => resolve(getters.payment_methods))
                                .finally(() => { is_payment_methods_default_removed = false })
                    })
                })
            }
        } else {
            return Promise.resolve([])
        }
    },

    removePaymentMethod({getters, commit}, payment_method_uuid) {
        const uuid = getters.current_account_uid

        const payment_method = getters.payment_methods.find(payment_method => payment_method.UUID == payment_method_uuid)
        is_payment_methods_default_removed = payment_method && payment_method.Rank == 1

        return uuid
            ? axiosIntents.delete(`/customer/${ uuid }/payment-methods/${ payment_method_uuid }`)
                .then(() => Promise.resolve(true))
                .catch(error => Promise.reject(error))
            : noCurrentAccountUid()
    },

    removeAboutToExpirePaymentMethod({getters}, payment_method_uuid) {
        const uuid = getters.current_account_uid

        const payment_method = getters.payment_methods.find(payment_method => payment_method.UUID == payment_method_uuid)
        is_payment_methods_default_removed = payment_method && payment_method.Rank == 1

        return uuid
            ? axiosIntents.delete(`/customer/${ uuid }/invalid-payment-methods/${ payment_method_uuid }`)
                .then(() => Promise.resolve(true))
                .catch(error => Promise.reject(error))
            : noCurrentAccountUid()
    },

    setDefaultPaymentMethod({getters, dispatch}, payment_method_uuid) {
        const uuid = getters.current_account_uid

        if (uuid) {
            unsubscribeAll()

            return axiosIntents.put(`/customer/${ uuid }/payment-methods/${ payment_method_uuid }`, { Rank: 1 })
                .then(() => dispatch('subscribePaymentMethods'))
                .then(() => Promise.resolve(true))
                .catch(error => {
                    dispatch('subscribePaymentMethods').catch(()=> {})

                    return Promise.reject(error)
                })
        }

        return noCurrentAccountUid()
    },

    unsubscribePaymentMethods({commit}, with_reset_payment_methods) {
        unsubscribeAll()

        if (with_reset_payment_methods) {
            commit('savePaymentMethods', [])
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
