import i18n from '@/i18n'

import { KILO_BASE_2 } from '@/consts'
import { firstLetterToUpperCase } from '@/utils/common.js'

export default function addDetailsToAvailablePlan (plan) {
    const plan_characteristics = plan.Characteristics || {}

    const details = {}

    let cost = '0.00'
    let origianl_price
    let discount
    let offer_credit

    const name = plan.Availabilities?.[0]?.RetailName || ''

    const isAllowAutoRenew = plan_characteristics.AllowAutoRenew && plan_characteristics.AllowAutoRenew != 'false'

    const offerPeriodCount = plan_characteristics['ocs.OfferPeriodCount'] || ''
    const offerPeriodInterval = plan_characteristics['ocs.OfferPeriodInterval'] || ''

    const initialData = plan_characteristics['ocs.RecurringDataAllowance']
    const initialVoice = plan_characteristics['ocs.RecurringVoiceAllowance']
    const initialSMS = plan_characteristics['ocs.RecurringSMSAllowance']
    const initialMMS = plan_characteristics['ocs.RecurringMMSAllowance']

    const initialIDDVoice = plan_characteristics['ocs.RecurringIDDVoiceAllowance']
    const initialIDDSMS = plan_characteristics['ocs.RecurringIDDSMSAllowance']

    if (plan.Availabilities?.[0]?.RetailCents && plan.Availabilities[0].RetailCents !== 0) 
        cost = (plan.Availabilities[0].RetailCents / 100).toFixed(2)

    if (plan.origianl_price) origianl_price = (plan.origianl_price /100).toFixed(2)
    if (plan.discount) discount = (plan.discount / 100).toFixed(2)
    if (plan.offer_credit) offer_credit = (plan.offer_credit / 100).toFixed(2)

    if (plan_characteristics['ocs.TimePeriodInterval']) {
        const splited = plan_characteristics['ocs.TimePeriodInterval'].split('day')

        const timePeriodIntervalresultString = splited.length == 1 
                                                    ? firstLetterToUpperCase(i18n.t(splited[0]) || splited[0] || '')
                                                    : splited[0] == '1' ?  `1 ${i18n.t('day')}` : `${splited[0]} ${i18n.t('days')}`

        details[`${isAllowAutoRenew ? i18n.t('renewable_period') : i18n.t('service_period')}:`] = timePeriodIntervalresultString
    }

    if (plan_characteristics['ocs.DataUsageRule'] == 'Unlimited') {
        details[`${i18n.t('data')}:`] = { value:  i18n.t('unlimited') }
    } else if (plan_characteristics['ocs.DataUsageRule'] == 'CasualRate' && (!initialData || initialData < 1)) {
        details[`${i18n.t('data')}:`] = { value:  i18n.t('casual_rate') }
    } else if (initialData && !isNaN(initialData) && +initialData !== 0) {
        const data_initial = +initialData

        const data_to_show = data_initial >= KILO_BASE_2 
                        ? Number.isInteger(data_initial / KILO_BASE_2)
                            ? `${(data_initial / KILO_BASE_2).toFixed(0)} ${i18n.t('unit.GB')}`
                            : `${(data_initial / KILO_BASE_2).toFixed(2)} ${i18n.t('unit.GB')}`
                        : `${data_initial} ${i18n.t('unit.MB')}`
                    
        details[`${i18n.t('data')}:`] = { value:  data_to_show }
    }	

    const sub_infos = []

    let data_speed
    let data_speed_dl = 'Unlimited'
    let data_speed_ul = 'Unlimited'
    if (plan_characteristics['ocs.SpeedLimitDL'] && plan_characteristics['ocs.SpeedLimitDL'] != 'Unlimited') {
        data_speed_dl = `${plan_characteristics['ocs.SpeedLimitDL']}bps`
    }
    if (plan_characteristics['ocs.SpeedLimitUL'] && plan_characteristics['ocs.SpeedLimitUL'] != 'Unlimited') {
        data_speed_ul = `${plan_characteristics['ocs.SpeedLimitUL']}bps`
    }
    if (data_speed_dl == 'Unlimited' && data_speed_ul == 'Unlimited') {
        data_speed = "In-bundle data at your plan's speed"
    } else if (data_speed_dl == data_speed_ul) {
        data_speed = `Speeds capped at ${data_speed_dl} down + up`
    } else {
        data_speed = `Speeds capped at ${data_speed_dl} down / ${data_speed_ul} up`
    }
    if (data_speed) {
        sub_infos.push(data_speed)
    }

    if (plan_characteristics['ocs.DataUsageRule'] == 'Throttled') {
        let data_capped

        switch (plan_characteristics['ocs.Throttle']) {
            case 'Throttle1.2M1.2M':
                data_capped = `Additional Endless Data capped at 1.2Mbps`
                break;
            case 'Throttle2.0M2.0M':
                data_capped = `Additional Endless Data capped at 2Mbps`
                break;
            case 'Throttle2M2M':
                data_capped = `Additional Endless Data capped at 2Mbps`
                break;
            case '':
                break;
        }
        if (data_capped) {
            sub_infos.push(data_capped)
        }
    }

    details[`${i18n.t('data')}:`] = details[`${i18n.t('data')}:`] 
        ? {...details[`${i18n.t('data')}:`], sub_infos}
        : {sub_infos}

    if (plan_characteristics['ocs.VoiceUsageRule'] == 'Unlimited') {
        details[`${i18n.t('voice2')}:`] = i18n.t('unlimited')
    } else if (plan_characteristics['ocs.VoiceUsageRule'] == 'CasualRate' && (!initialVoice || initialVoice < 1)) {
        details[`${i18n.t('voice2')}:`] = i18n.t('casual_rate')
    } else if (initialVoice && !isNaN(initialVoice) && +initialVoice !== 0) {
        details[`${i18n.t('voice2')}:`] = `${initialVoice} ${i18n.t('unit.mins')}`
    }

    if (plan_characteristics['ocs.SMSUsageRule'] == 'Unlimited') {
        details[`${i18n.t('SMS2')}:`] = i18n.t('unlimited')
    } else if (plan_characteristics['ocs.SMSUsageRule'] == 'CasualRate' && (!initialSMS || initialSMS < 1)) {
        details[`${i18n.t('SMS2')}:`] = i18n.t('casual_rate')
    } else if (initialSMS && !isNaN(initialSMS) && +initialSMS !== 0) {
        details[`${i18n.t('SMS2')}:`] = `${initialSMS} ${i18n.t('unit.SMS')}`
    }

    if (plan_characteristics['ocs.MMSUsageRule'] == 'Unlimited') {
        details[`${i18n.t('MMS2')}:`] = i18n.t('unlimited')
    } else if (plan_characteristics['ocs.MMSUsageRule'] == 'CasualRate' && (!initialMMS ||initialMMS < 1)) {
        details[`${i18n.t('MMS2')}:`] = i18n.t('casual_rate')
    } else if (initialMMS && !isNaN(initialMMS) && +initialMMS !== 0) {
        details[`${i18n.t('MMS2')}:`] = `${initialMMS} ${i18n.t('unit.MMS')}`
    }

    if (plan_characteristics['ocs.IDDVoiceUsageRule'] == 'Unlimited') {
        details[`${i18n.t('international_voice')}:`] = i18n.t('unlimited')
    } else if (plan_characteristics['ocs.IDDVoiceUsageRule'] == 'CasualRate' && (!initialIDDVoice || initialIDDVoice < 1)) {
        details[`${i18n.t('international_voice')}:`] = i18n.t('casual_rate')
    } else if (initialIDDVoice && !isNaN(initialIDDVoice) && +initialIDDVoice !== 0) {
        details[`${i18n.t('international_voice')}:`] = `${initialIDDVoice} ${i18n.t('unit.mins')}`
    }

    if (plan_characteristics['ocs.IDDSMSUsageRule'] == 'Unlimited') {
        details[`${i18n.t('international_SMS')}:`] = i18n.t('unlimited')
    } else if (plan_characteristics['ocs.IDDSMSUsageRule'] == 'CasualRate' && (!initialIDDSMS || initialIDDSMS < 1)) {
        details[`${i18n.t('international_SMS')}:`] = i18n.t('casual_rate')
    } else if (initialIDDSMS && !isNaN(initialIDDSMS) && +initialIDDSMS !== 0) {
        details[`${i18n.t('international_SMS')}:`] = `${initialIDDSMS} ${i18n.t('unit.SMS')}`
    }

    const more_details = plan.Availabilities?.[0]?.RetailDescription

    return {...plan, details, name, cost, origianl_price, discount, offer_credit, offerPeriodCount, offerPeriodInterval, hasSubscribeSign: isAllowAutoRenew, more_details }
};