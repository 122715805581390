import Vue from 'vue'
import dynStorage from '@/utils/storage-dynamic'

let BROADBAND_FLOW_ID = 0
const BROADBAND_FLOW = 'broadband-flow'

const state = {
    broadband_flow: {},
}

const getters = {
    broadband_flow: state => state.broadband_flow,
}

const mutations = {
    saveBroadbandFlow(state, broadband_flow) {
        const { id, uuid, location, transfer, service, plan, with_wifi_router, rsf } = broadband_flow

        if (!id) {
            broadband_flow.id = `ufb-${ (new Date()).getTime() }${ ++BROADBAND_FLOW_ID }`
        }

        const stored_broadband_flow = {
            id: broadband_flow.id,
            uuid: uuid,
            location: {
                Formatted: location.Formatted,
                City: location.City,
                Country: location.Country,
                PostCode: location.PostCode,
                Lines: location.Lines,
                LocationProvider: location.LocationProvider,
                LocationProviderIDX: location.LocationProviderIDX,
            },
            transfer: transfer,
            service: {
                ID: service.ID,
            },
            plan: {
                ID: plan.ID,
                Name: plan.Name,
                Price: plan.Price,
                OrderType: plan.OrderType,
            },
            with_wifi_router: with_wifi_router || false,
            rsf: rsf || {},
        }

        dynStorage.set(BROADBAND_FLOW, stored_broadband_flow, false, true)

        state.broadband_flow = broadband_flow
    },

    setBroadbandUUID(state, uuid) {
        if (uuid) {
            Vue.set(state.broadband_flow, 'uuid', uuid)
        } else {
            Vue.delete(state.broadband_flow, 'uuid')
        }
    },

    setBroadbandModemInfo(state, modem) {
        if (modem && Object.keys(modem).length) {
            Vue.set(state.broadband_flow, 'modem', {
                ID: modem.UUID,
                Name: modem.Name,
                PriceCents: modem.PriceCents || 0,
            })
        } else {
            Vue.delete(state.broadband_flow, 'modem')
        }
    },

    loadBroadbandFlow() {
        if (!Object.keys(state.broadband_flow).length) {
            state.broadband_flow = dynStorage.get(BROADBAND_FLOW, true) || {}
        }
    },

    resetBroadbandFlow() {
        state.broadband_flow = {}
        dynStorage.remove(BROADBAND_FLOW)
    },
}

const actions = {
    loadBroadbandModemInfo({getters, commit, dispatch}, broadband_flow) {
        return dispatch('ufbCart', broadband_flow).then(({data}) => {
            const modem = Array.isArray(data?.Items)
                ? data.Items.find(item => item.Type == "TYPE_PRODUCT" && item.VirtualType == "VT_NONE")
                : null

            commit('setBroadbandUUID', data?.UUID)
            commit('setBroadbandModemInfo', modem)
            commit('saveBroadbandFlow', getters.broadband_flow)

            return Promise.resolve(getters.broadband_flow.modem)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
