import dynStorage from '@/utils/storage-dynamic';

import firebase from 'firebase/app';

import { auth } from '@/utils/firebase.js';

import i18n from '@/i18n';

const displayName = (creds) => {
	if (!creds) return '';
	if (!creds.displayName || !creds.displayName.trim()) return creds.email || '';

	const [first_name, last_name] = creds.displayName.split('  ').filter((x) => x);
 
	if (!last_name || (first_name.length + last_name.length > 33)) return first_name;

	return `${first_name} ${last_name}`;
};

export default {
  state: {
    autologin: Promise.resolve(),
    processing_autologin: null,
    auth_user: null,
    auth_token: null,
  },

  getters: {
    is_processing_autologin: (state) => Boolean(state.processing_autologin),
    is_processing_preload: (state) => Boolean(state.processing_autologin),

    is_auth: (state) => Boolean(state.auth_user),

    auth_user: (state) => state.auth_user,
    auth_token: (state) => state.auth_token,

    auth_user_uid: (state) => (state.auth_user && state.auth_user.uid) || '',
    auth_user_has_password: (state) => state.auth_user && state.auth_user.providerData?.[0]?.providerId == 'password',
    is_auth_user_social_logged_in: (state, getters) => getters.is_auth && !getters.auth_user_has_password,

	  auth_user_first_name: (state) => {
      const [firstName = ''] =  (state.auth_user?.displayName ?? '').split('  ')
      return firstName
    },
    auth_user_last_name: (state) => {
      const [_, lastName = ''] =  (state.auth_user?.displayName ?? '').split('  ')
      return lastName
    },
    auth_user_email: (state) => state.auth_user?.email,
    
    auth_display_name: (state) => (state.auth_user?.displayName ?? '').replace('  ', ' '),
	auth_user_avatar: (state) => state.auth_user?.photoURL,

	auth_user_profile_creds: (state, getters) => ({
		first_name: getters.account_first_name || getters.auth_user_first_name,
		last_name: getters.account_last_name || getters.auth_user_last_name,
		email: getters.auth_user_email,
		customer_email: getters.account_email,
		phone: getters.account_phone,
		external_ref5: getters.account_external_ref5,
        displayName: `${getters.account_first_name || getters.auth_user_first_name}  ${getters.account_last_name || getters.auth_user_last_name}`
	}),
    auth_user_display_name: (state, getters) => displayName(getters.auth_user_profile_creds),
  },

  actions: {
	  updateAuthUserProfile({ getters, dispatch, commit }, { FirstName, LastName, ExternalRef5, MobileNumber, CustomerEmail, update_MVNE_customer = true }) {
	    const current_user = auth().currentUser;
        const displayName = `${FirstName}  ${LastName}`;
        const Email = CustomerEmail || getters.auth_user_profile_creds.customer_email;

		return current_user.updateProfile({ displayName })
        .then(() => {
		    commit('SAVE_AUTH_USER', auth().currentUser);
            // No return
            if (update_MVNE_customer) {
                dispatch('UpdateMVNECustomer', { FirstName, LastName, ExternalRef5, Email, MobileNumber });
            }
		})
	},

    CheckAuthUserPassword({}, pass) {
      const current_user = auth().currentUser;
		  const current_user_email = auth().currentUser.email;
  
		  const credential = auth.EmailAuthProvider.credential(current_user_email, pass);

      return current_user.reauthenticateWithCredential(credential).catch((error) => {
		    if (error.code === 'auth/wrong-password') {
                return Promise.reject(`${i18n.t('the_password_is_invalid')}, ${i18n.t('please_enter_correct_one')}`)
		    } else {
                return Promise.reject(error.message)
		    }
	  	});
    },

	ChangeAuthUserPassword({}, { pass, passnew }) {
		const current_user = auth().currentUser;
		const current_user_email = auth().currentUser.email;
  
		const credential = auth.EmailAuthProvider.credential(current_user_email, pass);
  
		return current_user
		  .reauthenticateWithCredential(credential)
		  .then(() => {
			return current_user.updatePassword(passnew);
		  })
		  .catch((error) => {
			if (error.code === 'auth/wrong-password') {
			  throw new Error(`${i18n.t('old_password_is_invalid')}, ${i18n.t('please_enter_correct_one')}`);
			} else {
			  throw new Error(error.message);
			}
		  });
	},

	ChangeAuthUserEmail({ getters, dispatch, commit }, { pass, email }) {
		const current_user = auth().currentUser;
		const current_user_email = auth().currentUser.email;
  
		const credential = auth.EmailAuthProvider.credential(current_user_email, pass);

        // Don't update MVNE Customer when Auth User Email is changed
        // const FirstName = getters.auth_user_profile_creds.first_name;
        // const LastName = getters.auth_user_profile_creds.last_name;
        // const ExternalRef5 = getters.auth_user_profile_creds.external_ref5;
        // const MobileNumber = getters.auth_user_profile_creds.phone;
  
		return current_user
		  .reauthenticateWithCredential(credential)
		  .then(() => {
			return current_user.updateEmail(email)
			.then(() => {
				commit('SAVE_AUTH_USER', auth().currentUser);

                // No return
                // dispatch('UpdateMVNECustomer', { FirstName, LastName, ExternalRef5, Email: email, MobileNumber });
			});
		  })
		  .catch((error) => {
			if (error.code === 'auth/wrong-password') {
			  throw new Error(`${i18n.t('the_password_is_invalid')}, ${i18n.t('please_enter_correct_one')}`);
			} else {
			  throw new Error(error.message);
			}
		  });
	},

	ForgotPassword({}, email) {
		return firebase.auth().sendPasswordResetEmail(email);
	},

    FirebaseSignUp({ getters, dispatch }, user_data) {
      return firebase
        .auth()
        .createUserWithEmailAndPassword(user_data.email, user_data.passnew)
        .then((userCredential) => {
		  // Signed in
          dispatch('logAnalyticsEventSignUp', { parameters: { method: "password" }})

		  const user = userCredential.user;
          const uid = user.uid;

          const displayName = (user_data.firstname && user_data.lastname) ? `${user_data.firstname.trim()}  ${user_data.lastname.trim()}` : ''

		  return user.updateProfile(
			  	{ displayName }
			  ).then(() => {
				return dispatch('GetAccountByUid', uid).then((account)=> {
					if (account) {
						return dispatch('FirebaseOnAuth');
					} else {
					  return dispatch('CreateAccount', { user_data: {}, uid }).then(()=> {
						return dispatch('FirebaseOnAuth');
					  });
					}
				  });
			  })
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    FirebaseSignInEmailPassword({ getters, dispatch }, { email, login, pass }) {
      const sign_in_email = email || login

      return firebase
        .auth()
        .signInWithEmailAndPassword(sign_in_email, pass)
        .then(() => {
          // Signed in
          dispatch('logAnalyticsEventLogin', { parameters: { method: "password" }});

          return dispatch('FirebaseOnAuth');
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    FirebaseSignInWithCustomToken({ getters, dispatch }, token) {
      return firebase
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          // Signed in
          console.log('FirebaseSignInWithCustomToken Signed in');

          dispatch('logAnalyticsEventLogin', { parameters: { method: "token" }});

          return dispatch('FirebaseOnAuth');
        })
        .catch((error) => {
          console.log('FirebaseSignInWithCustomToken error', error);

          return Promise.reject(error);
        });
    },

	FirebaseGoogleAuth({ getters, dispatch }) {
      console.info('Google sign in');
      const provider = new firebase.auth.GoogleAuthProvider();

	  provider.setCustomParameters({
		prompt: 'select_account',
	  });

	  firebase.auth().languageCode = 'en';

      return firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
		  //   const credential = result.credential;

          // This gives you a Google Access Token. You can use it to access the Google API.
          //   const token = credential.accessToken;
          
		  // The signed-in user info.
          const user = result.user;
          const uid = user.uid;

          //   check if already has a display name separated by two spaces
          if (user?.displayName && user.displayName.trim().split('  ').length !== 2) {
            // if not, replace all two or more spaces with one space and set two spaces instead of the first space
            const updated_displayName = user.displayName.trim().replace(/ {2,}/g, ' ').replace(' ', '  ')

            return user.updateProfile(
                { displayName: updated_displayName }
            ).then(() => {
                return dispatch('GetAccountByUid', uid).then((account)=> {
                    if (account) {
                        dispatch('logAnalyticsEventLogin', { parameters: { method: "google" }});
        
                        return dispatch('FirebaseOnAuth');
                    } else {
                        return dispatch('CreateAccount', { user_data: {}, uid }).then(()=> {
                            dispatch('logAnalyticsEventSignUp', { parameters: { method: "google" }});
        
                            return dispatch('FirebaseOnAuth');
                        });
                    }
                })
            })
          }

          //   else do nothing with the displayName
          return dispatch('GetAccountByUid', uid).then((account)=> {
			if (account) {
                dispatch('logAnalyticsEventLogin', { parameters: { method: "google" }});

				return dispatch('FirebaseOnAuth');
			} else {
				return dispatch('CreateAccount', { user_data: {}, uid }).then(()=> {
                    dispatch('logAnalyticsEventSignUp', { parameters: { method: "google" }});

					return dispatch('FirebaseOnAuth');
			  	});
			}
		  });
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    },

    FirebaseAppleAuth({ getters, dispatch, commit }) {
      console.info('Apple sign in');
      const provider = new firebase.auth.OAuthProvider('apple.com');

	  provider.setCustomParameters({
		prompt: 'select_account',
	  });

	  firebase.auth().languageCode = 'en';

      return firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
		  //   const credential = result.credential;

          // The signed-in user info.
          const user = result.user;
          const uid = user.uid;

          // You can also get the Apple OAuth Access and ID Tokens.
          //   const accessToken = credential.accessToken;
          //   const idToken = credential.idToken;

          //   check if already has a display name separated by two spaces
          if (user?.displayName && user.displayName.trim().split('  ').length !== 2) {
            // if not, replace all two or more spaces with one space and set two spaces instead of the first space
            const updated_displayName = user.displayName.trim().replace(/ {2,}/g, ' ').replace(' ', '  ')

            return user.updateProfile(
                { displayName: updated_displayName }
            ).then(() => {
                return dispatch('GetAccountByUid', uid).then((account)=> {
                    if (account) {
                        dispatch('logAnalyticsEventLogin', { parameters: { method: "apple" }});
        
                        return dispatch('FirebaseOnAuth');
                    } else {
                        dispatch('logAnalyticsEventSignUp', { parameters: { method: "apple" }});
        
                        return dispatch('CreateAccount', { user_data: {}, uid }).then(()=> {
                            return dispatch('FirebaseOnAuth');
                        });
                    }
                })
            })
          }

          //   else do nothing with the displayName
          return dispatch('GetAccountByUid', uid).then((account)=> {
			if (account) {
                dispatch('logAnalyticsEventLogin', { parameters: { method: "apple" }});

				return dispatch('FirebaseOnAuth');
			} else {
                dispatch('logAnalyticsEventSignUp', { parameters: { method: "apple" }});

				return dispatch('CreateAccount', { user_data: {}, uid }).then(()=> {
					return dispatch('FirebaseOnAuth');
			  	});
			}
		  });
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    },

    FirebaseGetUpdatedAuthToken({ commit }) {
        const user = firebase.auth().currentUser;

        if (user) {
            return user.getIdToken().then(token => {
      		  commit('SAVE_AUTH_TOKEN', token);

              return Promise.resolve(token);
      		}).catch(error => {
                console.log('FirebaseGetUpdatedAuthToken Error', error);

                return Promise.resolve(null);
            })
        }

        return Promise.resolve(null);
    },

    FirebaseOnAuth({ getters, dispatch, commit }, { firebase_auto_login } = {}) {
      const current_auth_user_uid = dynStorage.get('current-auth-user-uid', false);

	  const auth_state_changed = new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                unsubscribe();

                dispatch('FirebaseLogout')
            } else if (current_auth_user_uid && user.uid !== current_auth_user_uid) {
                unsubscribe();

                reject();
            }

            resolve(user);
        });
      });

	  return auth_state_changed
        .then((user) => {
            console.info('User is signed in', user);

	  		if (!user) return Promise.resolve();

            dynStorage.set('current-auth-user-uid', user.uid, null, false);

      		user.getIdToken().then((token) => {
      		  commit('SAVE_AUTH_TOKEN', token);
      		});

            if (user.photoURL) {
                commit('SAVE_AUTH_USER', user);
            } else {
                commit('SAVE_AUTH_USER', {
                    ...user,
                    photoURL: user.providerData?.[0]?.photoURL,
                });
            }

	  		const auth_user_uid = getters.auth_user_uid;
        // console.info('auth_user_uid', auth_user_uid);

      		return dispatch('GetCustomersUuidsByUserUid', auth_user_uid);
	  	})
		.then(() => {
			return dispatch('mockupCustomer');
		})
		.catch((error) => {
			dispatch('FirebaseLogout');
		 
			return Promise.reject(error);
		});
    },

    FirebaseLogout({ getters, dispatch, commit }) {
        console.log('FirebaseLogout')

        dispatch('Logout');

        if (getters.is_account) {
            dispatch('UnbindCurrentAccount').then(() => {
                dispatch('UnbindBindBillingRoutes');
                dispatch('UnbindCustomerAllProducts');
                dispatch('UnbindCurrentProductGetUsageTrigger');

                if (getters.is_account_postpay) {
                    dispatch('UnbindPostpayProductBalance');
                }
            })
        }

        commit('SAVE_CURRENT_ACCOUNT_UID', null);
        commit('SET_CUSTOMER_CURRENT_PRODUCT', null);
        commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', '');
        commit('SET_IS_CUSTOMER_CURRENT_ADDONS_PAYG', false);
        commit('DELETE_INVOICES');
        commit('resetInvoices_v3')
        commit('RESET_PAYMENT_HISTORY')
        commit('saveMockupUser', {})
        commit('SET_IS_MOBILE_ONBOARDING', false);
        commit('eshopCartReset_v2');
        commit('eshopPurchasedReset_v2');
        commit('resetDeliveryAddresses');
        commit('RESET_CUSTOMER_GROUPS_AND_MEMBERS');
        commit('RESET_MOBILE_GROUPS_AND_MEMBERS');
        commit('RESET_CUSTOMER_INVITES');
        commit('RESET_MOBILE_INVITES');
        commit('RESET_USAGE_CHARGES');
        commit('RESET_SIM_SETTINGS')
        commit('RESET_SIM_SETTINGS_ROAMING')
        commit('SET_NO_ADDONS_NOTIFIED', false)

        if (getters.is_app_support_service_intercom) {
            commit('LOGOUT_INTERCOM_CHAT');
        } else {
            commit('LOGOUT_ZENDESK_CHAT');
        }

        commit('eshopCheckoutFlowsReset_v2')

        dispatch('GetCatalogServices');

    	return firebase
          .auth()
          .signOut()
          .then(() => {
			commit('FIREBASE_LOGOUT');
		  });
    },

    FirebaseAutoLogin({ dispatch, commit, getters }) {
      commit('SAVE_PROCESSING_AUTOLOGIN', true);

      dispatch('BindGetCatalogServicesTrigger');
      dispatch('BindGetCatalogServicesSldTrigger');

      const auth_state_changed = new Promise((resolve) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      const autologin = auth_state_changed
        .then((user) => {
          dispatch('QuickReloadSupport');

          if (user) {
            dispatch('get_is_hide_prepay_topup');

	        if (getters.current_account_uid) {
		        dispatch('BindCurrentAccount', getters.current_account_uid);
		        dispatch('BindCustomerAllProducts', getters.current_account_uid);

                if (getters.is_app_environment_billing_routing_allowed) {
                    dispatch('BindBillingRoutes', getters.current_account_uid);
                }
	        }
            
            return dispatch('FirebaseOnAuth', { firebase_auto_login: true });
          } else {
            return Promise.reject();
          }
        })
        .then(() => {})
        .catch(() => {
          dispatch('FirebaseLogout');
        })
        .finally(() => commit('SAVE_PROCESSING_AUTOLOGIN', false));

      commit('SAVE_AUTOLOGIN', autologin);

	  return autologin;
    },

    FirebaseUserDelete() {
      const current_user = auth().currentUser;

      return current_user.delete()
    },
  },

  mutations: {
    SAVE_PROCESSING_AUTOLOGIN(state, value) {
      state.processing_autologin = value;
    },

    SAVE_AUTOLOGIN(state, autologin) {
      state.autologin = autologin;
    },

    SAVE_AUTH_USER(state, user) {
        state.auth_user = {...user };
    },

    SAVE_AUTH_TOKEN(state, token) {
        if (state.auth_token != token) {
            state.auth_token = token;
        }
    },

    FIREBASE_LOGOUT(state) {
      state.auth_user = null;
      state.auth_token = null;
    },
  },
};
