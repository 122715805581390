import store from '@/store'
import axios from '@/utils/axios-intents'

import StoreCache from '@/utils/StoreCache'

const cached = {
    ufb_plans: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

import dynStorage from '@/utils/storage-dynamic'

const UFB_SELECTED_PRODUCT = 'ufb-selected-product'

import { db } from '@/utils/firebase'

const unsubscribes = {}
const unsubscribeAll = () => {
    for (const uuid in unsubscribes) {
        unsubscribes[ uuid ]()
        delete unsubscribes[ uuid ]
    }
}

const ufbPayloadFromBroadbandFlow = ({ uuid, location, transfer, service, plan, with_wifi_router, rsf, contact_phone }) => {
    const auth_user_profile_creds = store.getters.auth_user_profile_creds

    const payload = {
        UUID: uuid,
        SPID: store.getters.app_spid,
        CustomerUUID: store.getters.current_account_uid,
        Address: {
            ...(location ? {
                City: location.City,
                Country: location.Country,
                Postcode: location.PostCode,
                Lines: location.Lines,
                LocationProvider: location.LocationProvider,
                LocationProviderIDX: location.LocationProviderIDX,
            } : {}),
            // PrequalUUID: service.ID,
            Email: auth_user_profile_creds.email,
            Name: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
            Phone: auth_user_profile_creds.phone,
        },
        ExternalReference: {
            ...(transfer && transfer?.required ? {
                OrderType: "TransferPrimary",
                TransferFrom: {
                    Provider: transfer.provider.name,
                    AccountHolderName: transfer.account.holder,
                    AccountNumber: transfer.account.number,
                    ConnectionID: transfer.connection.ID,
                },
            } : plan ? {
                OrderType: plan.OrderType,
            } : {}),
            ...(service ? {
                ServiceType: "Unmanaged",
                ServiceAvailabilityUUID: service.ID,
            } : {}),
            ...(rsf ? {
                RSFDate: rsf.StartTimeNanos,
            } : {}),
            SiteContactName: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
            SiteContactPhone: contact_phone || auth_user_profile_creds.phone,
            SiteContactEmail: auth_user_profile_creds.email,
            ...(location ? {
                LocationProvider: location.LocationProvider,
                LocationProviderIDX: location.LocationProviderIDX,
            } : {}),
        },
        Items: [],
    }

    if (plan) {
        payload.Items = [{
            ItemUUID: plan.ID,
            ProductAvailabilityUUID: plan.ID,
            SPID: store.getters.app_spid,
            Type: "TYPE_VIRTUAL",
            VirtualType: "VT_PLAN",
        }]

        if (with_wifi_router) {
            payload.Items.push({
                ItemUUID: plan.ID,
                ProductAvailabilityUUID: plan.ID,
                SPID: store.getters.app_spid,
                Type: "TYPE_PRODUCT",
                VirtualType: "VT_NONE"
            })
        }
    }

    return payload
}

const state = {
    ufb_products: [],
    ufb_product_uuid: null,
}

const getters = {
    ufb_product: state => state.ufb_products.find(ufb_product => ufb_product.UUID == state.ufb_product_uuid),
    ufb_products: state => state.ufb_products || [],
}

const mutations = {
    saveUFBProducts(state, ufb_products) {
        state.ufb_products = ufb_products || []
    },

    selectFirstUFBProduct(state) {
        if (state.ufb_products.length) {
            if (!state.ufb_product_uuid) {
                state.ufb_product_uuid = dynStorage.get(UFB_SELECTED_PRODUCT)
            }

            if (!(state.ufb_product_uuid && state.ufb_products.findIndex(ufb_product => ufb_product.UUID == state.ufb_product_uuid) > -1)) {
                state.ufb_product_uuid = state.ufb_products[0].UUID
            }
        } else {
            state.ufb_product = null
            state.ufb_product_uuid = null
        }
    },

    selectUFBProduct(state, UUID) {
        state.ufb_product_uuid = UUID

        dynStorage.set(UFB_SELECTED_PRODUCT, state.ufb_product_uuid, false)
    },

    resetUFBProduct() {
        state.ufb_products = []
        state.ufb_product_uuid = null

        unsubscribeAll()
    },
}

const actions = {
    ufbSearchAddress({}, payload) {
        return axios.get('/ufb/searchAddress', { params: payload })
            .then(({data}) => Promise.resolve(data))
    },

    ufbServiceAvailability({getters}, Location) {
        const payload = {
            SPID: getters.app_spid,
            LocationProvider: Location.LocationProvider,
            LocationProviderIDX: Location.LocationProviderIDX,
        }

        return axios.get('/ufb/service_availability', { params: payload }).then(({data}) => {
            return data.Status == 'service_available'
                || data.Status == 'service_scope_required'
                || data.Status == 'service_installation_required'
                    ? Promise.resolve(data)
                    : Promise.reject(new Error('No Fibre services available'))
        })
    },

    ufbPlans({commit}, { provider, provider_idx, refresh }) {
        const uuid = `${ provider }|${ provider_idx }`

        if (!refresh && uuid in cached.ufb_plans.uuids && !cached.ufb_plans.uuids[uuid].expired()) {
            return Promise.resolve(cached.ufb_plans.uuids[uuid].cachedData())
        } else {
            const params = {
                LocationProvider: provider,
                LocationProviderIDX: provider_idx,
            }

            return axios.get('/ufb/plans', { params }).then(({data}) => {
                const plans = Array.isArray(data?.Plans)
                    ? data?.Plans
                    : []

                if (!(uuid in cached.ufb_plans.uuids)) {
                    cached.ufb_plans.uuids[uuid] = new StoreCache(cached.ufb_plans.lifetime)
                }
                cached.ufb_plans.uuids[uuid].fix(plans)

                return Promise.resolve(cached.ufb_plans.uuids[uuid].cachedData())
            })
        }
    },

    ufbChangePlan({getters}, { UUID, ProductAvailabilityUUID }) {
        const payload = {
            UUID,
            ProductAvailabilityUUID,
            CustomerUUID: getters.current_account_uid,
        }

        return axios.post('/ufb/change-plan', payload)
    },

    ufbCart({commit}, broadband_flow) {
        return axios.post('/ufb/cart', ufbPayloadFromBroadbandFlow(broadband_flow))
    },

    ufbCheckout({getters, commit, dispatch}, broadband_flow) {
        return axios.post('/ufb/checkout', ufbPayloadFromBroadbandFlow(broadband_flow)).then(({data}) => {
            return getters.is_checkout_payment_confirmation && data?.ClientSecret
                ? dispatch('stripePaymentConfirmation', {
                    card_token: broadband_flow.payment_method?.cardId || getters.defaultPaymentCardId,
                    client_secret: data.ClientSecret,
                }).then(() => {
                    commit('resetBroadbandFlow')

                    return Promise.resolve(data)
                }).catch(error => {
                    error.srtripe_client_secret = data.ClientSecret

                    return Promise.reject(error)
                })
                : Promise.resolve(data)
        }).then(data => {
            return data?.OrderID
                ? dispatch('ufbCheckoutOrderStatus', data.OrderID)
                : Promise.resolve(data)
        }).then(() => {
            const PrequalUUID = broadband_flow?.service?.ID // ufbPayloadFromBroadbandFlow(broadband_flow) => ExternalReference.ServiceAvailabilityUUID || Address.PrequalUUID
            
            return PrequalUUID
                ? dispatch('ufbCheckSubscribeProduct', PrequalUUID)
                : Promise.resolve()
        })
    },

    ufbCheckoutOrderStatus({getters, dispatch}, OrderID) {
        return axios.get(`/eshop/v2/customer/${ getters.current_account_uid }/order/${ OrderID }`).then(({data}) => {
            return data?.Status
                ? data.Status == 'COMPLETED' || data.Status == 'PROCESSING'
                    ? Promise.resolve(data)
                    : data.Status == 'FAILED'
                        ? Promise.reject({
                            message: data?.OrderNotes?.[0]?.Note || 'Order failed',
                            order_status: true
                        })
                        : new Promise((resolve, reject) => {
                            setTimeout(() => {
                                dispatch('ufbCheckoutOrderStatus', OrderID)
                                    .then(data => resolve(data))
                                    .catch(error => reject(error))
                            }, 250)
                        })
                : Promise.resolve(data)
        })
    },

    ufbCheckSubscribeProduct({getters, commit, dispatch}, PrequalUUID) {
        const timeout = new Date().getTime() + (3 * 60 * 1000) // 3 min

        return dispatch('ufbSubscribeProducts').then(() => new Promise(resolve => {
            const interval = setInterval(() => {
                const ufb_product = getters.ufb_products.find(ufb_product => ufb_product.PrequalUUID == PrequalUUID)

                switch (true) {
                    case Boolean(ufb_product): {
                        commit('selectUFBProduct', ufb_product.UUID)
                    }

                    case new Date() > timeout: {
                        clearInterval(interval)
                        resolve()
                    }
                }
            }, 250)
        }))
    },

    ufbSubscribeProducts({getters, commit}) {
        const uuid = getters.current_account_uid

        if (uuid) {
            if (!(uuid in unsubscribes)) {
                unsubscribeAll()

                return new Promise(resolve => {
                    unsubscribes[uuid] = db.collection(`/customers/${ uuid }/ufb_products`).onSnapshot(collection => {
                        const ufb_products = []
    
                        collection.forEach((doc) => {
                            const ufb_product = doc.data()
    
                            if (!ufb_product.DeletedAtNanos) {
                                ufb_products.push(ufb_product)
                            }
                        })
    
                        commit('saveUFBProducts', ufb_products)
                        commit('selectFirstUFBProduct')

                        resolve(getters.ufb_products)
                    })
                })
            }
        } else {
            return Promise.resolve([])
        }
    },

    ufbUnsubscribeProducts() {
        unsubscribeAll()
    },

    ufbOrderTimeSlots({getters}, { OrderUUID, Mode }) {
        const params = {
            OrderUUID,
            CustomerUUID: getters.current_account_uid,
            Mode,
        }

        return axios.get(`/ufb/order/${ OrderUUID }/time_slots`, { params })
                    .then(({data}) => Promise.resolve(data?.AvailableTimeSlots || []))
    },

    ufbBookOrderAppointment({getters}, payload) {
        if (!payload.SPID) {
            payload.SPID = getters.app_spid
        }

        return axios.post(`/ufb/order/${ payload.UUID }/book`, payload)
            .then(({data}) => Promise.resolve(data))
    },
}

export default {
	state,
	getters,
	mutations,
	actions,
}