import Vue from 'vue'
import axiosIntents from '@/utils/axios-intents'

import StoreHash from '@/utils/StoreHash'

const cashed = {
    sim_settings: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 minutes
    }
}

const requests = {}



const DEPENDENCIES = {
    CallForwarding: {
        CFUActive: { value: true, dependency: 'EnableAllowIncomingOutgoingCalls' },
        CFBActive: { value: true, dependency: 'EnableAllowIncomingOutgoingCalls' },
        CFNRYActive: { value: true, dependency: 'EnableAllowIncomingOutgoingCalls' },
        CFNRCActive: { value: true, dependency: 'EnableAllowIncomingOutgoingCalls' },
    },

    CallRestriction: {
        CallWaitingEnabled: { value: true, dependency: 'EnableAllowIncomingCalls' },
        AllowCallHold: { value: true, dependency: 'EnableAllowIncomingCalls' },
    },

    Barring: {
        AllowIncomingCalls: { value: false, dependency: 'DisableAllowIncomingCalls' },
        AllowOutgoingCalls: { value: false, dependency: 'DisableAllowOutgoingCalls' },
    },
}
const DEPENDENCY_SETTINGS = {
    EnableAllowIncomingCalls: {
        Barring: {
            AllowIncomingCalls: true,
        },
    },
    EnableAllowIncomingOutgoingCalls: {
        Barring: {
            AllowIncomingCalls: true,
            AllowOutgoingCalls: true,
        },
    },

    DisableAllowIncomingCalls: {
        CallForwarding: {
            CFUActive: false,
            CFBActive: false,
            CFNRYActive: false,
            CFNRCActive: false,
        },
    
        CallRestriction: {
            CallWaitingEnabled: false,
            AllowCallHold: false,
        },
    },
    DisableAllowOutgoingCalls: {
        CallForwarding: {
            CFUActive: false,
            CFBActive: false,
            CFNRYActive: false,
            CFNRCActive: false,
        },
    },
}



export const mergedSIMSettings = (settings, mixin) => {
    for (const group in mixin) {
        if (!settings.hasOwnProperty(group)) {
            settings[group] = {}
        }

        for (const key in mixin[group]) {
            settings[group][key] = mixin[group][key]
        }
    }

    return settings
}

const state = {
    sim_settings: {},
    sim_settings_dependencies: null,
}

const getters = {
    sim_settings: (state, getters) => state.sim_settings.hasOwnProperty(getters.customer_current_product_uuid)
        ? state.sim_settings[getters.customer_current_product_uuid]
        : {},
    
    sim_settings_dependencies: state => state.sim_settings_dependencies,
}

const mutations = {
    setSIMSettings(state, { settings, MobileUUID, dependencies }) {
        Vue.set(state.sim_settings, MobileUUID, Object.defineProperty(settings, 'dependencies', { value: dependencies }))

        if (!cashed.sim_settings.uuids.hasOwnProperty(MobileUUID)) {
            cashed.sim_settings.uuids[MobileUUID] = new StoreHash(cashed.sim_settings.lifetime)
        }
        cashed.sim_settings.uuids[MobileUUID].fix()
    },

    setSimSettingsDependencies(state, dependencies) {
        state.sim_settings_dependencies = dependencies
    },

    RESET_SIM_SETTINGS(state) {
        state.sim_settings = {}
        state.sim_settings_dependencies = null

        cashed.sim_settings.uuids = {}

        for (const MobileUUID in requests) {
            delete requests[MobileUUID]
        }
    },
}

const actions = {
    getSIMSettings({getters, commit, dispatch}, force) {
        const MobileUUID = getters.customer_current_product_uuid

        if (requests.hasOwnProperty(MobileUUID)) {
            return requests[MobileUUID].type === 'get'
                ? requests[MobileUUID].request
                : requests[MobileUUID].request.catch(() => dispatch('getSIMSettings', force))
        } else if (force || !cashed.sim_settings.uuids[MobileUUID] || cashed.sim_settings.uuids[MobileUUID].expired()) {
            const params = {
                MobileUUID,
                CustomerUUID: getters.current_account_uid,
            }

            const request = axiosIntents.get('/sim/v1/settings', { params }).then(({data: settings}) => {
                if (MobileUUID === getters.customer_current_product_uuid) {
                    commit('setSIMSettings', { settings, MobileUUID })
                }

                return Promise.resolve(getters.sim_settings)
            }).finally(() => {
                if (requests.hasOwnProperty(MobileUUID)
                    && requests[MobileUUID].type === 'get'
                ) {
                    delete requests[MobileUUID]
                }
            })

            requests[MobileUUID] = {
                type: 'get',
                request,
            }

            return request
        } else {
            return Promise.resolve(getters.sim_settings)
        }
    },

    updateSIMSettings({getters, commit, dispatch}, { updated, dependencies }) {
        const MobileUUID = getters.customer_current_product_uuid

        const settings = dependencies
            ? mergedSIMSettings(updated, dependencies)
            : updated

        /**
         * getSIMSettings необходим для актуализации sim_settings перед их изменением
         * так как API не позволяет изменять только указанные пользователем settings
         * из-за чего могут быть некорректно установлены другие settings
         */
        const request_id = requests.hasOwnProperty(MobileUUID)
            && requests[MobileUUID].type === 'put'
                ? requests[MobileUUID].request_id + 1
                : 1

        const request = (
            requests.hasOwnProperty(MobileUUID)
                ? requests[MobileUUID].type === 'put'
                    ? requests[MobileUUID].request.catch(() => dispatch('getSIMSettings', true))
                    : requests[MobileUUID].request
                : dispatch('getSIMSettings', true)
        ).then(sim_settings => {
            let is_updated = true
            let settings_dependencies = null
            const dependency_matching_settings = [settings, sim_settings]

            if (MobileUUID === getters.customer_current_product_uuid) {
                for (const group in sim_settings) {
                    if (!settings.hasOwnProperty(group)) {
                        settings[group] = {}
                    }

                    for (const key in sim_settings[group]) {
                        if (settings[group].hasOwnProperty(key)) {
                            if (settings_dependencies === null && settings[group][key] !== sim_settings[group][key]) {
                                is_updated = false

                                if (DEPENDENCIES.hasOwnProperty(group)
                                    && DEPENDENCIES[group].hasOwnProperty(key)
                                    && DEPENDENCIES[group][key].value === settings[group][key]
                                    && DEPENDENCY_SETTINGS.hasOwnProperty(DEPENDENCIES[group][key].dependency)
                                ) {
                                    const dependency = DEPENDENCIES[group][key].dependency

                                    for (const dep_group in DEPENDENCY_SETTINGS[dependency]) {
                                        for (const dep_key in DEPENDENCY_SETTINGS[dependency][dep_group]) {
                                            let is_dependency_required = true

                                            for (let i = 0; i < dependency_matching_settings.length; i++) {
                                                if (dependency_matching_settings[i].hasOwnProperty(dep_group)
                                                    && dependency_matching_settings[i][dep_group].hasOwnProperty(dep_key)
                                                    && dependency_matching_settings[i][dep_group][dep_key] === DEPENDENCY_SETTINGS[dependency][dep_group][dep_key]
                                                ) {
                                                    is_dependency_required = false
                                                    break
                                                }
                                            }

                                            if (is_dependency_required) {
                                                if (settings_dependencies === null) {
                                                    settings_dependencies = new Array({
                                                        group,
                                                        key,
                                                        value: settings[group][key],
                                                    }, {
                                                        group: dep_group,
                                                        key: dep_key,
                                                        value: DEPENDENCY_SETTINGS[dependency][dep_group][dep_key],
                                                    })
                                                } else {
                                                    settings_dependencies.push({
                                                        group: dep_group,
                                                        key: dep_key,
                                                        value: DEPENDENCY_SETTINGS[dependency][dep_group][dep_key],
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            settings[group][key] = sim_settings[group][key]
                        }
                    }
                }
            }

            if (settings_dependencies !== null) {
                let promise_resolve, promise_reject = null
                const promise = new Promise((resolve, reject) => {
                    promise_resolve = resolve
                    promise_reject = reject
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    commit('setSimSettingsDependencies', null)
                })
                
                commit('setSimSettingsDependencies', {
                    dependencies: settings_dependencies,
                    promise,
                    resolve: promise_resolve,
                    reject: promise_reject,
                })
            }

            return is_updated
                ? Promise.resolve(getters.sim_settings)
                : getters.sim_settings_dependencies !== null
                    ? Promise.reject(getters.sim_settings_dependencies)
                    : axiosIntents.put('/sim/v1/settings', {
                        MobileUUID,
                        CustomerUUID: getters.current_account_uid,
                        Settings: settings,
                    }).then(({data: settings}) => {
                        if (MobileUUID === getters.customer_current_product_uuid) {
                            commit('setSIMSettings', { settings, MobileUUID, dependencies })
                        }

                        return Promise.resolve(getters.sim_settings)
                    })
        }).finally(() => {
            if (requests.hasOwnProperty(MobileUUID)
                && requests[MobileUUID].type === 'put'
                && requests[MobileUUID].request_id === request_id
            ) {
                delete requests[MobileUUID]
            }
        })

        requests[MobileUUID] = {
            type: 'put',
            request,
            request_id,
        }

        return request
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
