import errMessage from '@/helpers/err-message'

export default {
    state: {
        error_modal: {
            show: false,
            message: null,
            title: undefined,
            has_header: true,
            has_message: true,
            button_caption: undefined,
        },
    },
    
    getters: {
        error_modal_show: state => state.error_modal.show,
        error_modal_message: state => state.error_modal.message,
        error_modal_title: state => state.error_modal.title,
        error_modal_has_header: state => state.error_modal.has_header,
        error_modal_has_message: state => state.error_modal.has_message,
        error_modal_button_caption: state => state.error_modal.button_caption,
    },

    mutations: {
        showErrorMessage(state, error) {
            state.error_modal = {
                show: true,
                message: typeof error =='object' ? error?.error_message : error,
                title: error?.title,
                has_header: typeof error =='object' ? Boolean(error?.has_header) : true,
                has_message: typeof error =='object' ? Boolean(error?.has_message) : true,
                button_caption: error?.button_caption,
            }
        },

        hideError(state) {
            state.error_modal = {
                show: false,
                message: null,
                title: undefined,
                has_header: true,
                has_message: true,
                button_caption: undefined,
            }
        },
    },

    actions: {
        showError({ commit }, error = { message: null, title: undefined, has_message: true, has_header: true, button_caption: undefined }) {
            commit('showErrorMessage', {
                error_message: errMessage(error),
                title: error?.title,
                has_message: typeof error?.has_message == 'boolean' ? error.has_message : true,
                has_header: typeof error?.has_header == 'boolean' ? error.has_header : true,
                button_caption: error?.button_caption,
            })
        },

        showErrorPromise({ getters, dispatch }, error) {
            return dispatch('showError', error).then(() => new Promise(resolve => {
                const interval = setInterval(() => {
                    if (!getters.error_modal_show) {
                        clearInterval(interval)
                        resolve()
                    }
                }, 250)
            }))
        },

        showErrorMessage({ commit }, error_message) {
            commit('showErrorMessage', error_message)
        },

        hideError({ commit }) {
            commit('hideError')
        },
    },
}