export const PAYMENT_HISTORY_STATUSES = {
    'REFUNDED':                 { status: 'REFUNDED',                   title: 'REFUNDED',      color: 'var(--payment-history-refunded-color)'        },
    'PARTIALLY REFUNDED':       { status: 'PARTIALLY REFUNDED',         title: 'REFUNDED',      color: 'var(--payment-history-refunded-color)'        },
    'COMPLETED':                { status: 'COMPLETED',                  title: 'COMPLETED',     color: 'var(--payment-history-completed-color)'       },
    'NEEDS AUTHORIZATION':      { status: 'NEEDS AUTHORIZATION',        title: 'OTHERS',        color: 'var(--payment-history-others-color)'          },
    'FAILED':                   { status: 'FAILED',                     title: 'DECLINED',      color: 'var(--payment-history-declined-color)'        },
    'DECLINED':                 { status: 'DECLINED',                   title: 'DECLINED',      color: 'var(--payment-history-declined-color)'        },
    'CANCELLED':                { status: 'CANCELLED',                  title: 'DECLINED',      color: 'var(--payment-history-declined-color)'        },
    'PAYMENT METHOD REQUIRED':  { status: 'PAYMENT METHOD REQUIRED',    title: 'ACTION REQ',    color: 'var(--payment-history-action-required-color)' },
    'PAYMENT METHOD INVALID':   { status: 'PAYMENT METHOD INVALID',     title: 'ACTION REQ',    color: 'var(--payment-history-action-required-color)' },
    'PAYMENT METHOD EXPIRED':   { status: 'PAYMENT METHOD EXPIRED',     title: 'ACTION REQ',    color: 'var(--payment-history-action-required-color)' },
    'NO FUNDS':                 { status: 'NO FUNDS',                   title: 'ACTION REQ',    color: 'var(--payment-history-action-required-color)' },
    'PENDING':                  { status: 'PENDING',                    title: 'PENDING',       color: 'var(--payment-history-pending-color)'         },
    'UNKNOWN':                  { status: 'UNKNOWN',                    title: 'UNKNOWN',       color: 'var(--payment-history-unknown-color)'         },
}