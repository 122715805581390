import i18n from '@/i18n';
import axios from 'axios';

import dynStorage from '@/utils/storage-dynamic';

const MOBILE_ONBOARDING_FINALISE_URL = `${location.origin}/mobile-onboarding-finalise`

export default {
    state: {
		JWT: null,
        is_mobile_onboarding: false,
        is_web_onboarding: false,
        mobile_onboarding_finalise_url: MOBILE_ONBOARDING_FINALISE_URL,
        onboarding_current_step: null,
	},

    getters: {
		JWT: (state) => state.JWT,
        is_mobile_onboarding: (state) => state.is_mobile_onboarding,
        is_web_onboarding: (state) => state.is_web_onboarding,
        mobile_onboarding_finalise_url: (state) => state.mobile_onboarding_finalise_url,
        onboarding_current_step: (state) => state.onboarding_current_step,
	},

    actions: {
		saveJWT({commit}, JWT) {
			commit('SAVE_JWT', JWT);
		},

        setIsMobileOnboarding({ commit }, is_mobile_onboarding) {
            commit('SET_IS_MOBILE_ONBOARDING', is_mobile_onboarding)
        },

        setIsWebOnboarding({ commit }, is_web_onboarding) {
            commit('SET_IS_WEB_ONBOARDING', is_web_onboarding)
        },

		async checkAddons({ dispatch }, { JWT, productUuid, customerUuid }) {
			try {
			  	const data = await dispatch('checkingExistingAddons', customerUuid);
				console.log('checkAddons data', data)
			  	return !!(data.AddOns && data.AddOns.length);
			} catch (error) {
				console.log('checkAddons error', error)
				console.log(error)
			}

			try {
			  	const { data } = await dispatch("onboardingGetPrePayProduct", { JWT, productUuid, customerUuid });
				  console.log('onboardingGetPrePayProduct data', data)
			  	return !!(data.AddOns && data.AddOns.length);
			} catch (_) {
			  	return false;
			}
		},

        async getActivatedProductWithAddons({ dispatch, commit, getters }, { CustomerUUID, ProductInstanceUUID }) {
            if (!CustomerUUID || !ProductInstanceUUID) {
                console.log('getActivatedProductWithAddons No UUIDs')

                return Promise.resolve(null)
            }

            try {
                await dispatch('BindCustomerAllProducts', CustomerUUID)

                const customer_all_products = getters.customer_all_products

                const customer_current_product = customer_all_products.find(product => product.UUID == ProductInstanceUUID)

                console.log('getActivatedProductWithAddons customer_current_product', customer_current_product)

                if (customer_current_product) {
                    const is_activated = customer_current_product.Status == 4 && customer_current_product.BillingReference

                    const has_addons = customer_current_product.Addons && customer_current_product.Addons.length > 0

                    console.log('getActivatedProductWithAddons is_activated', is_activated)
                    console.log('getActivatedProductWithAddons has_addons', has_addons)

                    if (is_activated && has_addons) {
                        return Promise.resolve(customer_current_product)
                    }
                }

                return Promise.resolve(null) 

            } catch (error) {
                console.log('getActivatedProductWithAddons error', error)

                return Promise.resolve(null)
            }
        },

		async checkTypeService({ dispatch, commit, getters }, { data, next }) {
			console.log('checkTypeService data', data)

			try {
				const customerUUID = data.AdoptionRequest?.CustomerUUID
				const productInstanceUUID = data.AdoptionRequest?.ProductInstanceUUID
                let customer_current_product = null
                let has_addons = false

				if (customerUUID) {
					console.log('customerUUID', customerUUID)
                    if (productInstanceUUID) {
						console.log('productInstanceUUID', productInstanceUUID)
                        await dispatch('BindCustomerAllProducts', customerUUID)

                        if (getters.is_app_environment_billing_routing_allowed) {
                            await dispatch('BindBillingRoutes', customerUUID)
                        }
                        
                        const customer_all_products = getters.customer_all_products

                        console.log('checkTypeService customer_all_products', customer_all_products)

                        customer_current_product = customer_all_products.find(product => product.UUID == productInstanceUUID)

                        console.log('checkTypeService customer_current_product', customer_current_product)

                        if (customer_current_product) {
                            has_addons = customer_current_product.Addons && customer_current_product.Addons.length > 0
                        }
                    }

                    if (!has_addons) {
						console.log("!has_addons")
                        commit('SET_CUSTOMER_CURRENT_PRODUCT', { UUID: productInstanceUUID, ServiceType: 'Not Activated' })
                        
                        await dispatch('SelectAccount', customerUUID)
                    } else {
						console.log("has_addons")
                        await dispatch('SelectAccount', customerUUID)

                        await dispatch('SetCustomerCurrentProduct', customer_current_product)

                        const customer_current_product_uuid = getters.customer_current_product_uuid

                        commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', customer_current_product_uuid)

                        return { has_addons }
                    }
				}
			} catch (error) {
				console.log('checkTypeService error', error)
			}

			const JWT = data.AuthResponse?.JWT;

			if (JWT) {
				dispatch('saveJWT', JWT)
			}

			const component = 'PayAddonsMockup'

			let prePurchasedProductCatalogUUID
			let prePurchasedProductType
			let isPrePurchasedProductFullyPaid
            let is_sim_swap = false
            let is_payg = false

			const metadata = data.AdoptionMetadataEntry?.Metadata

            console.log('checkTypeService metadata', metadata)

			if (metadata) {
				prePurchasedProductCatalogUUID = metadata["postpay-product-catalog-uuid"] || metadata["prepay-product-catalog-uuid"] || metadata["payg-product-catalog-uuid"]

                if (metadata["postpay-product-catalog-uuid"]) {
                    prePurchasedProductType = 'postpaymobile'
                } else if (metadata["prepay-product-catalog-uuid"]) {
                    prePurchasedProductType = 'prepaymobileAddon'
                } else if (metadata["payg-product-catalog-uuid"]) {
                    prePurchasedProductType = 'prepaymobileAddon'
                    is_payg = true
                }

                isPrePurchasedProductFullyPaid = metadata["fully-paid"] === true || metadata["fully-paid"] === 'true'

                sessionStorage.removeItem('is_sim_swap')

                is_sim_swap = Boolean(metadata['replacement-for'])

                console.log("Boolean(metadata['replacement-for']", Boolean(metadata['replacement-for']))
                console.log("is_sim_swap", is_sim_swap)

                if (is_sim_swap) {
                    sessionStorage.setItem('is_sim_swap', JSON.stringify(true));
                }
			}

			return {
			  component,
			  prePurchasedProductCatalogUUID,
              prePurchasedProductType,
              isPrePurchasedProductFullyPaid,
              is_sim_swap,
              is_payg,
			  productType: data.AdoptionRequest?.ProductType,
			  productUuid: data.AdoptionRequest?.ProductInstanceUUID,
			  customerUuid: data.AdoptionRequest?.CustomerUUID,
			};
		},

		async checkTokenFlow({ dispatch }, { JWT, token, cb }) {
			try {
			  const { data } = await dispatch('onboardingAdoptByToken', { JWT, token } );

			  if (!data) throw 'TOKEN_ERROR';

              const onboardingAdoptionResume_JWT = data.AuthResponse?.JWT;
              const adoption_uuid = data.AdoptionRequest?.UUID;

			  if (onboardingAdoptionResume_JWT && adoption_uuid) {
                sessionStorage.setItem('onboardingAdoptionResume_JWT', JSON.stringify(onboardingAdoptionResume_JWT));
                sessionStorage.setItem('adoption_uuid', JSON.stringify(adoption_uuid));
			  }
			  
			  return await dispatch("checkTypeService",{ data: data, next: cb });
			} catch (error) {
              sessionStorage.removeItem('onboardingAdoptionResume_JWT');
              sessionStorage.removeItem('adoption_uuid');
              
			  throw error;
			}
		},

		async noTokenFlow({ dispatch }, { JWT, customerUuid, cb }) {
			try {
			  console.log('noTokenFlow customerUuid', customerUuid);
			  if(!customerUuid) throw i18n.t('no_customer_UUID');

			  const data = await dispatch('checkingExistingMobileService', customerUuid);
			  return await dispatch("checkTypeService", {
				data: {
				  AuthResponse: { JWT },
				  AdoptionRequest: {
					...data,
					ProductInstanceUUID: data['UUID'],
					ProductType: 'prepaymobile',
				  },
				},
				next: cb,
			});
			} catch (_) {
			  console.log(_);
		  
			  throw 'NO_SERVICE';
			}
		},

		async attachByIpFlow({}, JWT) {
			try {
			  const { data } = await dispatch("onboardingAdoptByIp", { JWT });

              if (!data) throw 'CANT_ADOPT_BY_IP';

              const onboardingAdoptionResume_JWT = data.AuthResponse?.JWT;
              const adoption_uuid = data.AdoptionRequest?.UUID;

			  if (onboardingAdoptionResume_JWT && adoption_uuid) {
                sessionStorage.setItem('onboardingAdoptionResume_JWT', JSON.stringify(onboardingAdoptionResume_JWT));
                sessionStorage.setItem('adoption_uuid', JSON.stringify(adoption_uuid));
			  }

			  return await dispatch("checkTypeService", { data });
			} catch (_) {
			  throw 'CANT_ADOPT_BY_IP';
			}
		},
    },

    mutations: {
		SAVE_JWT (state, JWT) {
			state.JWT = JWT;
		},

        SET_IS_MOBILE_ONBOARDING (state, is_mobile_onboarding) {
			state.is_mobile_onboarding = is_mobile_onboarding;

            if (is_mobile_onboarding) {
                dynStorage.set('is_mobile_onboarding', is_mobile_onboarding, null, true);
            } else {
                dynStorage.remove('is_mobile_onboarding');
            }
		},

        SET_IS_WEB_ONBOARDING (state, is_web_onboarding) {
			state.is_web_onboarding = is_web_onboarding;
		},

        SET_ONBOARDING_CURRENT_STEP (state, onboarding_current_step) {
			state.onboarding_current_step = onboarding_current_step;

            if (onboarding_current_step) {
                dynStorage.set('onboarding_current_step', onboarding_current_step, null, true);
            } else {
                dynStorage.remove('onboarding_current_step');
            }
		},
	},
}