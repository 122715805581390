import appModal from '@/components/app-modal'

export default {
    props: {
        value: { default: false },
        autoShow: { type: Boolean, default: false },
    },

    components: {
        appModal,
    },

    mounted() {
        if (this.autoShow || this.value) {
            this.showModal()
        }
    },

    methods: {
        showModal() {
            this.$refs['app-modal'].showModal()
        },

        hideModal() {
            this.$refs['app-modal'].hideModal()
            this.$emit('closes')
            this.hideModalCompleted()
            this.$emit('input', false)
        },

        hideModalCompleted() {},
    },
}