import { db } from '@/utils/firebase.js'
import { firestoreAction } from 'vuexfire';

import dynStorage from '@/utils/storage-dynamic';

import axiosIntents from '@/utils/axios-intents'

import i18n from '@/i18n';

const mapPostpayUsage = usage => {
    const getUsageType = key => {
        switch (key) {
            case 'Data':
                return 'data'
            case 'Mins':
                return 'voice'
            case 'IDDMins':
                return 'idd_voice'
            case 'TXTs':
                return 'sms'
            case 'IDDTXTs':
                return 'idd_sms'
            case 'PXTs':
                return 'mms'
            default:
                return 'unknown'
        }
    }

    const mappedPostpayUsage = Object.keys(usage).map(key => {
        return {
            CarryOverAmount: usage[key]?.CarryOverAmount || 0,
            AmountRemaining: usage[key]?.RemainingAmount || 0,
            UsageType: getUsageType(key),
            UsageRule: usage[key]?.UsageRule,
        }
    })

    return mappedPostpayUsage
}

const state = {
    postpay_product: {},
    postpay_product_balance: { Value: 0 },
    postpay_current_and_next_billing_cycles: {},
}

const getters = {
    postpay_product: state => state.postpay_product,
    postpay_product_uuid: state => state.postpay_product.UUID,
    // postpay_product_catalog_uuid: state => state.postpay_product.ProductCatalogUUID,
    postpay_product_balance: state => state.postpay_product_balance?.Value ?? 0,

    postpay_current_balance: state => state.postpay_current_and_next_billing_cycles?.Balance ?? 0,
    postpay_current_billing_start_date_at_nanos: state => state.postpay_current_and_next_billing_cycles?.CurrentBillingCycle?.From ?? 0,
    postpay_current_billing_end_date_at_nanos: state => state.postpay_current_and_next_billing_cycles?.CurrentBillingCycle?.Upto ?? 0,
    postpay_next_billing_start_date_at_nanos: state => state.postpay_current_and_next_billing_cycles?.NextBillingCycle?.From ?? 0,
    postpay_next_billing_end_date_at_nanos: state => state.postpay_current_and_next_billing_cycles?.NextBillingCycle?.Upto ?? 0,

    /* postpay_product_billing_date: state => {
        if (!state.postpay_product) {
            return null
        } else if (state.postpay_product.BillingPeriodEndNanos) {
            // if we have the billing date information, use it...
            const billing_date_at_nanos = state.postpay_product.BillingPeriodEndNanos;
            const billing_date_at_miliseconds = Math.round(+billing_date_at_nanos/1000000);
            const billing_date = new Date(billing_date_at_miliseconds);

            if (billing_date < new Date()) return null;
            return billing_date;
        } else if (state.postpay_product.BillingPeriodStartNanos) {
            // else, calculate the billing date from when the plan was created
            const Default_Billing_Period_Days = 28;

            const billing_period_start_miliseconds = Math.round(+state.postpay_product.BillingPeriodStartNanos/1000000);
            const billing_period_miliseconds = +Default_Billing_Period_Days * MILLISECONDS_IN_A_DAY;

            const billing_date_at_miliseconds = billing_period_start_miliseconds + billing_period_miliseconds;
            const billing_date = new Date(billing_date_at_miliseconds);

            if (billing_date < new Date()) return null;
            return billing_date;
        } else {
            return null;
        }
    }, */
    postpay_product_billing_date: (state, getters) => {
        const billing_date_at_nanos = getters.postpay_next_billing_start_date_at_nanos;

        if (!billing_date_at_nanos) return null;

        const billing_date_at_miliseconds = Math.round(+billing_date_at_nanos/1_000_000);
        const billing_date = new Date(billing_date_at_miliseconds);

        return billing_date;
    },
}

const mutations = {
    savePostpayProduct(state, postpay_product) {
        state.postpay_product = postpay_product;

        if (postpay_product) {
            dynStorage.set('postpay_product', postpay_product, null, true);
        } else {
            dynStorage.remove('postpay_product');
        }
    },


    resetPostpayProduct(state) {
        state.postpay_product = {}
    },

    /* setPostpayProductBalance(state, balance) {
        state.postpay_product_balance = balance
    } */

    setPostpayCurrentAndNextBillingCycles(state, billing_cycles) {
        state.postpay_current_and_next_billing_cycles = billing_cycles
    },
}

const actions = {
    BindPostpayProductBalance: firestoreAction(({ bindFirestoreRef, getters }) => {
		return bindFirestoreRef('postpay_product_balance', 
			db
			.collection(getters.collection_customers_name)
			.doc(getters.current_account_uid)
			.collection('balances')
            .doc('postpay')
		)
	}),

    UnbindPostpayProductBalance: firestoreAction(({ unbindFirestoreRef }) => {
		return unbindFirestoreRef('postpay_product_balance');
	}),

    getCurrentPostpayProduct({getters, commit}) {
        const uuid = getters.current_account_uid
        const current_product_uuid = getters.customer_current_product_uuid

        return uuid
            ? db.doc(`customers/${ uuid }`).get().then(customer => {
                return customer.ref.collection(getters.collection_customers_mobiles_name).get()
            }).then(products => {
                if (products.docs.length) {
                    const current_postpay_product = products.docs.filter(doc => {
                        console.log('getCurrentPostpayProduct')
                        console.log('doc.id', doc.id)
                        console.log('current_product_uuid', current_product_uuid)

                        return doc.id == current_product_uuid
                    })

                    commit('savePostpayProduct', current_postpay_product[0].data())

                    return Promise.resolve(getters.postpay_product)
                } else {
                    commit('resetPostpayProduct')

                    return Promise.reject(new Error(i18n.t('you_dont_have_postpay_product')))
                }
            }).catch(error => {
                console.log('ERROR', `customers/${ uuid }/postpay_products`, error)
                console.dir(error)

                commit('resetPostpayProduct')

                return Promise.reject(error)
            })
            : Promise.reject(new Error(i18n.t('current_account_not_specified')))
    },

    redeemByVoucherCode({commit, dispatch}, code) {
        return dispatch('getCurrentPostpayProduct').then(postpay_product => {
            return axiosIntents.post(`/customer/:uuid/redeem_voucher/${ postpay_product.UUID }/${ code }`)
        }).then(({result}) => {
            // commit('savePostpayProduct', result)

            return Promise.resolve(result)
        }).catch(error => Promise.reject(error))
    },

    /* getPostpayProductBalance({getters, commit}) {
        const uuid = getters.current_account_uid

        return axiosIntents.get(`/customer/${ uuid }/account`)
                .then(({result}) => {
                    commit('setPostpayProductBalance',  result.Balance ?? 0)
                })
                .catch(error => {
                    console.log('Balance loading error', error)

                    commit('setPostpayProductBalance', 0)
                })
    }, */

    getPostpayCurrentAndNextBillingCycles({getters, commit}) {
        const uuid = getters.current_account_uid

        return axiosIntents.get(`/customer/${ uuid }/account`)
                .then(({result: billing_cycles}) => {
                    commit('setPostpayCurrentAndNextBillingCycles',  billing_cycles)
                })
                .catch(error => {
                    console.log('getCurrentAndNextBillingCycles loading error', error)

                    commit('setPostpayCurrentAndNextBillingCycles',  {})
                })
    },

    /* changePlan({getters, commit}, { planUUID, planAvailabilityUUID, ChangePlanMode }) {
        const uuid = getters.current_account_uid
        const instanceUUID = getters.customer_current_product_uuid
        // const current_postpay_product = getters.postpay_product

        if (!uuid || !instanceUUID || !planUUID) return Promise.reject('No UUID')

        return axiosIntents.post('/postpay/plan', {
            ChangePlanMode,
            "CustomerUUID": uuid,
            "NewAvailabilityUUID": planAvailabilityUUID,
            "NewProductCatalogUUID": planUUID,
            "ProductInstanceUUID": instanceUUID
          })
            // .then(({result}) => {
            //     commit(
            //         'savePostpayProduct', 
            //         {
            //             ...current_postpay_product, 
            //             ProductCatalogUUID: planUUID, 
            //             ProductAvailabilityUUID: planAvailabilityUUID 
            //         }
            //     )

            //     return Promise.resolve(result)
            // })
    }, */

    /* cancelChangePlan() {
        return axiosIntents.delete('/postpay/:uuid/cancel-change-plan')
    }, */

    payBill({getters, dispatch}, {PaymentMethodType, PaymentMethodID,  AmountCents}) {
        const CustomerUUID = getters.current_account_uid
        const ProductInstanceUUID = getters.customer_current_product_uuid

        if (!CustomerUUID) return Promise.reject('No Customer UUID')

        const payload = {
            AmountCents,
            PaymentMethodType,
            PaymentMethodID,
            ProductInstanceUUID,
        }

        return axiosIntents.post(`/customer/${CustomerUUID}/payment`, payload).then(({result}) => {
            if (result?.Status == 'completed') return true

            if (result?.ClientSecret) {
                if (getters.defaultPaymentCardId) {
                    if (PaymentMethodType === 'cards') {
                        return dispatch('stripePaymentConfirmation', {
                            card_token: PaymentMethodID || getters.defaultPaymentCardId,
                            client_secret: result.ClientSecret,
                        })
                    } else {
                        return Promise.reject({
                            card_token: getters.defaultPaymentCardId,
                            client_secret: result.ClientSecret,
                            reason: 'changePaymentType',
                            amount: AmountCents,
                            last4: getters.defaultPaymentCardLast4,
                        })
                    }
                } else {
                    return Promise.reject('No card')
                }
            }

            return true
        }).then(()=> {
            return new Promise(resolve => {
                setTimeout(() => {
                    dispatch('getPostpayCurrentAndNextBillingCycles').then(()=> {
                        resolve(true)
                    })
                }, 3000)
            })
        }).catch(error => Promise.reject(error))
    },

    GetPostpayProductUsage({ getters, commit }) {
        if (getters.is_getting_current_product_usage) return

        commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', true)

        const CustomerUUID = getters.current_account_uid
        const current_mobile_product_uuid = getters.customer_current_product_uuid

        return axiosIntents.post('postpay/balance', { CustomerUUID, UUID: current_mobile_product_uuid }).then(({result: usage}) => {
            commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', false)

            if (!usage) {
                commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE',[])
                return Promise.resolve(null)
            }

            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE', mapPostpayUsage(usage))
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_HAS_DATA', true)
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR', null)

            return Promise.resolve(usage)
        }).catch(error => {
            console.error('GetPostpayProductUsage Error:', error)
            
            commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', false)
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR', error)

            return Promise.resolve(null)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}