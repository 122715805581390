import i18n from '@/i18n'



export const INVOICES_LIMIT_DEFAULT = 11

export const INVOICE_STATUSES = {
    OPEN:    { status: 'OPEN',    title: 'OPEN',    color: 'var(--invoices-open-color)'    },
    PAID:    { status: 'PAID',    title: 'PAID',    color: 'var(--invoices-paid-color)'    },
    DUE:     { status: 'DUE',     title: 'DUE',     color: 'var(--invoices-due-color)'     },
    OVERDUE: { status: 'OVERDUE', title: 'OVERDUE', color: 'var(--invoices-overdue-color)' },
    VOID:    { status: 'VOID',    title: 'VOID',    color: 'var(--invoices-void-color)'    },
}

export const INVOICES_BILLING_CYCLES = {
    SEVEN_DAYS: {
        title: `7 ${i18n.t('days')}`,
        option: 'SEVEN_DAYS',
        type: 'daily',
        value: 7,
    },
    TWENTY_EIGHT_DAYS: {
        title: `28 ${i18n.t('days')}`,
        option: 'TWENTY_EIGHT_DAYS',
        type: 'daily',
        value: 28,
    },
    MONTHLY: {
        title: i18n.t('monthly_upper'),
        option: 'MONTHLY',
        type: 'monthly',
        value: 1,
    },
}