<template>
    <div class="card" :class="{
        'card--item': item,
        'card--hoverable': hoverable && !disabled,
    }">
        <slot></slot>

        <div v-if="disabled" class="card--disabled"></div>
    </div>
</template>

<script>
export default {
    props: {
        item:      { type: Boolean, default: false },
        hoverable: { type: Boolean, default: false },
        disabled:  { type: Boolean, default: false },
    },
}
</script>

<style>
</style>