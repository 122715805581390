import Vue from 'vue'
import StoreHash from '@/utils/StoreHash.js'
import axiosIntents from '@/utils/axios-intents'

import formatDate from '@/helpers/date-helpers/format-date'

import { PAYMENT_HISTORY_STATUSES } from '@/helpers/payment-history'

const PAYMENT_HISTORY_PAGES_LIFETIME = 5 * 60 * 1000 // 5 minute
const PAYMENTS_LIMIT_DEFAULT = 14

let cashed = {
    payment_history: {}
}

const state = {
    payment_history: {},
    payment_history_total: 0,
    is_payment_history_loading: false,
    payment_history_loading_error: {},
}

const getters = {
    payment_history: state => state.payment_history,
    payment_history_loading_error: state => state.payment_history_loading_error,
    payment_history_total: state => state.payment_history_total,
    payment_history_total_pages: (state, getters) => Math.ceil(getters.payment_history_total / PAYMENTS_LIMIT_DEFAULT),
    has_payment_history: (state, getters) => Boolean(getters.payment_history_total),
    is_payment_history_loading: state => state.is_payment_history_loading,
    paymentHistoryTransactionsByPage: (state, getters) => (page) => getters.payment_history[page] || [],
    paymentHistoryLoadingErrorByPage: (state, getters) => (page) => getters.payment_history_loading_error[page] || null,
}

const mutations = {
    SET_PAYMENT_HISTORY(state, { total, page, transactions }) {
        if (state.payment_history_total && state.payment_history_total != total) {
            state.payment_history = {}
            state.payment_history_loading_error = {}
            cashed.payment_history = {}
            state.is_payment_history_loading = false
        }

        state.payment_history_total = total

        Vue.set(state.payment_history, page, transactions)

        if (!cashed.payment_history[page]) {
            cashed.payment_history[page] = new StoreHash(PAYMENT_HISTORY_PAGES_LIFETIME)
        }

        cashed.payment_history[page].fix()
    },

    SET_PAYMENT_HISTORY_LOADING(state, is_payment_history_loading) {
        state.is_payment_history_loading = is_payment_history_loading
    },

    SET_PAYMENT_HISTORY_LOADING_ERROR(state, { page, error}) {
        Vue.set(state.payment_history_loading_error, page, error)
    },

    RESET_PAYMENT_HISTORY_LOADING_ERRORS(state) {
        state.payment_history_loading_error = {}
    },

    RESET_PAYMENT_HISTORY (state) {
        state.payment_history = {}
        state.payment_history_loading_error = {}
        cashed.payment_history = {}
        state.is_payment_history_loading = false
        state.payment_history_total = 0
    },
}

const actions = {
    getPaymentHistory({getters, commit}, {
        page: PageNumber = 1,
        limit: PageSize = PAYMENTS_LIMIT_DEFAULT,
        SortBy = 'CreatedAtNanos',
        SortDesc = true 
    } = {}) {
        if (getters.is_payment_history_loading) { return getters.is_payment_history_loading }

        if (
            cashed.payment_history[PageNumber]
            && !cashed.payment_history[PageNumber].expired()
            && getters.paymentHistoryTransactionsByPage(PageNumber).length
        ) {
            return getters.paymentHistoryTransactionsByPage(PageNumber)
        }

        commit('SET_PAYMENT_HISTORY_LOADING', true)
        
        return axiosIntents.get('/v2/customer/:uuid/charges', { params: { PageNumber, PageSize, SortBy, SortDesc } }).then(({data}) => {
            commit('SET_PAYMENT_HISTORY_LOADING_ERROR', { error: null, page: PageNumber })

            const transactions = data.Transactions || []

            const mapped_transactions = transactions.map(
                transaction => ({
                    ...transaction,
                    transaction_date: transaction.CreatedAt ? formatDate(new Date(transaction.CreatedAt), 'DD MMM YYYY @ HH:mm') : '',
                    transaction_status: PAYMENT_HISTORY_STATUSES[transaction.Status?.toUpperCase()] || PAYMENT_HISTORY_STATUSES.UNKNOWN,
                })
            )

            commit('SET_PAYMENT_HISTORY', {
                total: data.PageInfo.TotalItemCount,
                page: PageNumber,
                transactions: mapped_transactions,
            })

            return Promise.resolve(getters.paymentHistoryTransactionsByPage(PageNumber))
        }).catch(error => {
            commit('SET_PAYMENT_HISTORY_LOADING_ERROR', { error, page: PageNumber })

            return Promise.resolve([])
        }).finally(() => {
            commit('SET_PAYMENT_HISTORY_LOADING', false)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}