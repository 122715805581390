const SIMS_MULTI_PLANS_SETTINGS = {
    family: {
        SelfManaged:             { default: true,  editable: true           },
        Grouped:                 process.env.VUE_APP_SIMS_MULTI_PLANS_GROUPED_ALLOWED == 'true'
                               ? { default: true,  editable: false          }
                               : { default: false, editable: false          },
        UseMasterBillingAccount: process.env.VUE_APP_SIMS_MULTI_PLANS_USE_MASTER_BILLING_ALLOWED == 'true'
                               ? { default: true,  editable: false          }
                               : { default: false, editable: false          },
        MinSimsAvailable:        {                                 value: 2 },
    },

    solo: {
        SelfManaged:             { default: true,  editable: true           },
        Grouped:                 process.env.VUE_APP_SIMS_MULTI_PLANS_GROUPED_ALLOWED == 'true'
                               ? { default: process.env.VUE_APP_SIMS_MULTI_PLANS_SOLO_GROUPED_DEFAULT == 'true', editable: process.env.VUE_APP_SIMS_MULTI_PLANS_SOLO_GROUPED_EDITABLE == 'true' }
                               : { default: false, editable: false          },
        UseMasterBillingAccount: process.env.VUE_APP_SIMS_MULTI_PLANS_USE_MASTER_BILLING_ALLOWED == 'true'
                               ? { default: false, editable: true           }
                               : { default: false, editable: false          },
        MinSimsAvailable:        {                                 value: 1 },
        AdvancedOptions:         {                                 value: process.env.VUE_APP_SIMS_MULTI_PLANS_SOLO_ADVANCED == 'ON'
                                                                        ? true
                                                                        : process.env.VUE_APP_SIMS_MULTI_PLANS_SOLO_ADVANCED == 'OFF'
                                                                            ? false
                                                                            : null },
        AdvancedOptionsHidden:   {                                 value: process.env.VUE_APP_SIMS_MULTI_PLANS_SOLO_ADVANCED_HIDDEN == 'true' },
    },

    companion: {
        SelfManaged:             { default: true,  editable: true           },
        Grouped:                 process.env.VUE_APP_SIMS_MULTI_PLANS_GROUPED_ALLOWED == 'true'
                               ? { default: true,  editable: false          }
                               : { default: false, editable: false          },
        UseMasterBillingAccount: process.env.VUE_APP_SIMS_MULTI_PLANS_USE_MASTER_BILLING_ALLOWED == 'true'
                               ? { default: false, editable: false          }
                               : { default: false, editable: false          },
        MinSimsAvailable:        {                                 value: 2 },
    },

    // VUE_APP_SIMS_MULTI_PLANS_FAMILY=false
    // VUE_APP_SIMS_MULTI_PLANS_COMPANION=false
    simple: { 
        SelfManaged:             { default: true,  editable: true           },
        Grouped:                 process.env.VUE_APP_SIMS_MULTI_PLANS_GROUPED_ALLOWED == 'true'
                               ? { default: process.env.VUE_APP_SIMS_MULTI_PLANS_SIMPLE_GROUPED_DEFAULT == 'true', editable: process.env.VUE_APP_SIMS_MULTI_PLANS_SIMPLE_GROUPED_EDITABLE == 'true' }
                               : { default: false, editable: false          },
        UseMasterBillingAccount: process.env.VUE_APP_SIMS_MULTI_PLANS_USE_MASTER_BILLING_ALLOWED == 'true'
                               ? { default: false, editable: false          }
                               : { default: false, editable: false          },
        MinSimsAvailable:        {                                 value: 1 },
        AdvancedOptions:         {                                 value: process.env.VUE_APP_SIMS_MULTI_PLANS_SIMPLE_ADVANCED == 'ON'
                                                                        ? true
                                                                        : process.env.VUE_APP_SIMS_MULTI_PLANS_SIMPLE_ADVANCED == 'OFF'
                                                                            ? false
                                                                            : null },
        AdvancedOptionsHidden:   {                                 value: process.env.VUE_APP_SIMS_MULTI_PLANS_SIMPLE_ADVANCED_HIDDEN == 'true' },
    },
}

export default SIMS_MULTI_PLANS_SETTINGS