import axiosIntents from '@/utils/axios-intents'

import StoreHash from '@/utils/StoreHash'

const cashed = {
    sim_settings_roaming: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 minutes
    }
}

const requests = {}

const state = {
    is_auto_roaming_charge_active: false,
}

const getters = {
    is_auto_roaming_charge_active: state => state.is_auto_roaming_charge_active,
}

const actions = {
    GetIsAutoRoamingChargeActive({ getters, commit }, force) {
        const MobileUUID = getters.customer_current_product_uuid

        if (requests.hasOwnProperty(MobileUUID)) {
            return requests[MobileUUID].type === 'get'
                ? requests[MobileUUID].request
                : requests[MobileUUID].request.catch(() => dispatch('GetIsAutoRoamingChargeActive', force))
        } else if (force || !cashed.sim_settings_roaming.uuids[MobileUUID] || cashed.sim_settings_roaming.uuids[MobileUUID].expired()) {
            const request = axiosIntents.get(`/sim/daily-roaming/auto-active/${ getters.current_account_uid }/${ MobileUUID }`).then(({data: settings})=> {
                if (MobileUUID === getters.customer_current_product_uuid) {
                    commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', { is_auto_roaming_charge_active: Boolean(settings.AutoActive), MobileUUID })
                }

                return Promise.resolve(getters.is_auto_roaming_charge_active)
            }).finally(() => {
                if (requests.hasOwnProperty(MobileUUID)
                    && requests[MobileUUID].type === 'get'
                ) {
                    delete requests[MobileUUID]
                }
            })

            requests[MobileUUID] = {
                type: 'get',
                request,
            }

            return request
        } else {
            return Promise.resolve(getters.is_auto_roaming_charge_active)
        }
    },

    SetIsAutoRoamingChargeActive({ getters, commit }, is_auto_active) {
        const MobileUUID = getters.customer_current_product_uuid

        const request_id = requests.hasOwnProperty(MobileUUID)
            && requests[MobileUUID].type === 'put'
                ? requests[MobileUUID].request_id + 1
                : 1

        const request = (
            requests.hasOwnProperty(MobileUUID)
                ? requests[MobileUUID].type === 'put'
                    ? requests[MobileUUID].request.catch(() => Promise.resolve(getters.is_auto_roaming_charge_active))
                    : requests[MobileUUID].request
                : Promise.resolve(getters.is_auto_roaming_charge_active)
        ).then(is_auto_roaming_charge_active => {
            let is_updated = true

            if (MobileUUID === getters.customer_current_product_uuid) {
                is_updated = is_auto_active === is_auto_roaming_charge_active
            }

            return is_updated
                ? Promise.resolve(getters.is_auto_roaming_charge_active)
                : axiosIntents.post('/sim/daily-roaming/auto-active', {
                    MobileUUID,
                    CustomerUUID: getters.current_account_uid,
                    AutoActive: is_auto_active,
                }).then(() => {
                    if (MobileUUID === getters.customer_current_product_uuid) {
                        commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', { is_auto_roaming_charge_active: is_auto_active, MobileUUID })
                    }
    
                    return Promise.resolve(getters.is_auto_roaming_charge_active)
                })
        }).finally(() => {
            if (requests.hasOwnProperty(MobileUUID)
                && requests[MobileUUID].type === 'put'
                && requests[MobileUUID].request_id === request_id
            ) {
                delete requests[MobileUUID]
            }
        })

        requests[MobileUUID] = {
            type: 'put',
            request,
            request_id,
        }

        return request
    },
}

const mutations = {
    SET_IS_AUTO_ROAMING_CHARGE_ACTIVE(state, { is_auto_roaming_charge_active, MobileUUID }) {
        state.is_auto_roaming_charge_active = is_auto_roaming_charge_active

        if (!cashed.sim_settings_roaming.uuids.hasOwnProperty(MobileUUID)) {
            cashed.sim_settings_roaming.uuids[MobileUUID] = new StoreHash(cashed.sim_settings_roaming.lifetime)
        }
        cashed.sim_settings_roaming.uuids[MobileUUID].fix()
    },

    RESET_SIM_SETTINGS_ROAMING(state) {
        state.is_auto_roaming_charge_active = false

        cashed.sim_settings_roaming.uuids = {}

        for (const MobileUUID in requests) {
            delete requests[MobileUUID]
        }
    },
}

export default {
    state,
    getters,
    mutations,
	actions,
};