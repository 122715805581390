import store from '@/store';

export default (to, from, next) => {
    const is_show_pay_bill_on_home = store.getters.is_app_environment_show_pay_bill_on_home;

    return {
        allow: is_show_pay_bill_on_home,
        redirect: { name: 'not-found' },
    }
};
