import axiosIntents from '@/utils/axios-intents'

import StoreCache from '@/utils/StoreCache.js'

const INTERCOM_CHAT_DEFAULT_SETTINGS =  {
    api_base: process.env.VUE_APP_INTERCOM_CHAT_API_BASE,
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
}

const DEFAULT_LIFETIME_USAGE = 1 * 60 * 60 * 1000 // 1 hour

export default {
    state: {
        intercom_collections_list: new StoreCache(DEFAULT_LIFETIME_USAGE),
        intercom_articles_list: new StoreCache(DEFAULT_LIFETIME_USAGE),
        intercom_articles_search: {
            searches: {},
            lifetime: 10 * 60 * 1000, // 10 minutes
        },
    },

    getters: {
        intercom_collections_list: state => state.intercom_collections_list.cachedData() || [],
        intercom_categories_list: (state, getters) => getters.intercom_collections_list.filter(collections_item => !collections_item.parent_id),
        intercom_sections_list: (state, getters) => getters.intercom_collections_list.filter(collections_item => collections_item.parent_id),
        intercom_articles_list: state => state.intercom_articles_list.cachedData() || [],
    },

    actions: {
        GetIntercomCollectionsList({ state, commit }) {
            const collections_list = state.intercom_collections_list

            if (!collections_list.expired()) {
                return Promise.resolve(collections_list.cachedData())
            }
           
            return axiosIntents.get('/intercom/help_center/collections?per_page=150').then(({data}={}) => {
                const collections = data?.data || []

                commit('SAVE_INTERCOM_COLLECTIONS_LIST', collections)

                return collections
            })
            .catch(error => {
                console.log({error});

                return Promise.resolve([])
            })
        },

        GetIntercomCategoriesList({ state, getters, dispatch }) {
            const collections_list = state.intercom_collections_list

            if (!collections_list.expired()) {
                return Promise.resolve(getters.intercom_categories_list)
            }

            return dispatch('GetIntercomCollectionsList').then(()=> {
                return getters.intercom_categories_list
            })
        },

        GetIntercomSectionsList({ state, getters, dispatch }) {
            const collections_list = state.intercom_collections_list

            if (!collections_list.expired()) {
                return Promise.resolve(getters.intercom_sections_list)
            }

            return dispatch('GetIntercomCollectionsList').then(()=> {
                return getters.intercom_sections_list
            })
        },

        GetIntercomArticlesList({ state, commit }) {
            const articles_list = state.intercom_articles_list

            if (!articles_list.expired()) {
                return Promise.resolve(articles_list.cachedData())
            }

            return axiosIntents.get('/intercom/articles?per_page=150').then(({data}={}) => {
                const articles = data?.data || []

                const published_articles = articles.filter(({state}) => state == 'published')

                commit('SAVE_INTERCOM_ARTICLES_LIST', published_articles)

                return published_articles
            })
            .catch(error => {
                console.log({error});

                return Promise.resolve([])
            })
        },

        FindIntercomArticles({ state, commit }, search_word) {
            const search_cache = state.intercom_articles_search.searches[search_word]

            if (search_cache && !search_cache.expired()) {
                return Promise.resolve(search_cache.cachedData())
            }

            return axiosIntents.get(`/intercom/articles/search?per_page=150&phrase=${search_word}&state=published`).then(({data}={}) => {
                const search_articles = data?.data?.articles || []

                const search_articles_ids = search_articles.map(({id}) => id)

                commit('SAVE_INTERCOM_ARTICLES_SEARCH', { search_word, search_articles_ids })

                return search_articles_ids
            }).catch( error => {
                console.info('Search Articles Error', error)

                return Promise.resolve([])
            })
        },

        InitIntercomChat({ commit, dispatch, getters }, should_chat_show = false) {
            const intercom_script_id = 'intercom-snippet'
            if (document.getElementById(intercom_script_id)) { return }

            window.intercomSettings = INTERCOM_CHAT_DEFAULT_SETTINGS;

            (function(){
                var w=window;
                var ic=w.Intercom;
                if(typeof ic==="function"){
                    ic('reattach_activator');
                    ic('update',w.intercomSettings);
                }else{
                    var d=document;
                    var i=function(){
                        i.c(arguments);
                    };
                    i.q=[];
                    i.c=function(args){
                        i.q.push(args);
                    };
                    w.Intercom=i;
                    var l=function(){
                        var s=d.createElement('script');
                        s.id = intercom_script_id
                        s.type='text/javascript';
                        s.async=true;
                        s.src='https://widget.intercom.io/widget/' + process.env.VUE_APP_INTERCOM_APP_ID;
                        var x=d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s,x);

                        s.onload = () => {
                            Intercom('hide');
            
                            if (getters.is_account) {
                                dispatch('LogInIntercomChat', should_chat_show)
                            } else {
                                commit('LOGOUT_INTERCOM_CHAT', should_chat_show)
                            }
                        };
                    };
                    if(document.readyState==='complete'){
                        l();
                    }else if(w.attachEvent){
                        w.attachEvent('onload',l);
                    }else{
                        w.addEventListener('load',l,false);
                    }
                }}
            )();
        },

        LogInIntercomChat({ commit, getters }, should_chat_show = false) {
            if (!getters.is_account) return

            axiosIntents.post('/support/login/:uuid?platform=web').then(({result}) => {
                if (!result) Promise.reject('Not Intercom Provider')

                result.Provider == 'Intercom'
                    ? commit('LOGIN_INTERCOM_CHAT', { should_chat_show, user_id: getters.current_account_uid, email: getters.account_email, user_hash: result.Token })
                    : commit('LOGOUT_INTERCOM_CHAT', should_chat_show)
            }).catch(error => {
                commit('LOGOUT_INTERCOM_CHAT', should_chat_show)

                console.log('IntercomChat get Token Error', error)
            })
        },
    },

    mutations: {
        SAVE_INTERCOM_COLLECTIONS_LIST(state, collections_list) {
            state.intercom_collections_list.fix(collections_list)
        },

        SAVE_INTERCOM_ARTICLES_LIST(state, articles_list) {
            state.intercom_articles_list.fix(articles_list)
        },

        SAVE_INTERCOM_ARTICLES_SEARCH(state, { search_word, search_articles_ids }) {
            if (!state.intercom_articles_search.searches[search_word]) {
                state.intercom_articles_search.searches[search_word] = new StoreCache(state.intercom_articles_search.lifetime)
            }

            state.intercom_articles_search.searches[search_word].fix(search_articles_ids)
        },

        LOGIN_INTERCOM_CHAT(state, { should_chat_show = false, user_id, email, user_hash } = {}) {
            if (window.Intercom) {
                Intercom('shutdown')

                Intercom('boot', {
                    ...INTERCOM_CHAT_DEFAULT_SETTINGS,
                    user_id,
                    email,
                    user_hash,
                })

                if (should_chat_show) {
                    Intercom('show')
                }
            }
        },

        LOGOUT_INTERCOM_CHAT(state, should_chat_show = false) {
            if (window.Intercom) {
                Intercom('shutdown')
    
                Intercom('boot', INTERCOM_CHAT_DEFAULT_SETTINGS)

                if (should_chat_show) {
                    Intercom('show')
                }
            }
        },
    }
}
