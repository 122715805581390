import axios from 'axios';
import errMessage from '@/helpers/err-message'

const state = {
    mobile_groups_and_members: [],
    mobile_invites: null,
}

const getters = {
    mobile_groups_and_members: state => state.mobile_groups_and_members,
    mobile_sent_invites: state => state.mobile_invites?.sent || [],
    mobile_incoming_invites: state => state.mobile_invites?.incoming || [],
}

const mutations = {
    SAVE_MOBILE_GROUPS_AND_MEMBERS(state, { mobile_groups_and_members = [], current_account_uid, customer_current_product_uuid } = {}) {
        if (!mobile_groups_and_members.length) {
            state.mobile_groups_and_members = []

            return
        }

        const mobile_groups_and_members_mapped = mobile_groups_and_members.map(({ MobileGroup, MobileGroupMembers }) => {
            let mobile_group_members = MobileGroupMembers || []

            if (current_account_uid && customer_current_product_uuid) {
                const current_mobile_group_member_index = mobile_group_members.findIndex(
                    group_member => group_member.CustomerUUID == current_account_uid && group_member.MobileProductUUID == customer_current_product_uuid
                )
    
                if (current_mobile_group_member_index > -1) {
                    const current_mobile_group_member = mobile_group_members.splice(current_mobile_group_member_index, 1)[0]

                    mobile_group_members = [current_mobile_group_member, ...mobile_group_members]
                }
            }

            mobile_group_members = mobile_group_members.map(mobile_group_member => {
                return {
                    ...mobile_group_member,
                    is_leader: mobile_group_member.CustomerUUID == MobileGroup.CustomerUUID && mobile_group_member.MobileProductUUID == MobileGroup.MobileProductUUID,
                }
            })

            return {
                ...MobileGroup,
                is_group_owner: MobileGroup.CustomerUUID == current_account_uid && MobileGroup.MobileProductUUID == customer_current_product_uuid,
                members_total_items_count: mobile_group_members.length,
                MobileGroupMembers: mobile_group_members,
            }
        })

        mobile_groups_and_members_mapped.sort((a, b) => a.CreatedAtNanos - b.CreatedAtNanos)

        state.mobile_groups_and_members = mobile_groups_and_members_mapped
    },

    DELETE_MOBILE_GROUP_MEMBER(state, { group_uuid, mobile_product_to_delete_uuid } = {}) {
        if (!group_uuid || !mobile_product_to_delete_uuid) return

        const group_of_a_member = state.mobile_groups_and_members.find(group => group.UUID == group_uuid)

        if (!group_of_a_member) return

        if (group_of_a_member.MobileGroupMembers.length > 2) {
            group_of_a_member.MobileGroupMembers = group_of_a_member.MobileGroupMembers.filter(
                member => member.MobileProductUUID != mobile_product_to_delete_uuid
            )
            
            group_of_a_member.members_total_items_count = group_of_a_member.MobileGroupMembers.length
        } else {
            state.mobile_groups_and_members = state.mobile_groups_and_members.filter(group => group.UUID != group_uuid)
        }
    },

    LEAVE_A_MOBILE_GROUP(state, group_uuid ) {
        state.mobile_groups_and_members = state.mobile_groups_and_members.filter(group => group.UUID != group_uuid)
    },

    RESET_MOBILE_GROUPS_AND_MEMBERS(state) {
        state.mobile_groups_and_members = []
    },

    SAVE_MOBILE_INVITES(state, invites) {
        state.mobile_invites = ({
            sent: invites?.Invites?.filter(invite => invite.ApprovalType == 'MOBILEGROUP_INVITE' || invite.ApprovalType == 'MOBILEGROUP_BILLING_INVITE').map(invite => ({...invite, invite_type: 'sent'})) || [],
            incoming: invites?.Approvals?.filter(invite => invite.ApprovalType == 'MOBILEGROUP_INVITE' || invite.ApprovalType == 'MOBILEGROUP_BILLING_INVITE').map(invite => ({...invite, invite_type: 'incoming'})) || [],
        })
    },

    RESET_MOBILE_INVITES(state) {
        state.mobile_invites = null
    },
}

const actions = {
    GetMobileGroupsAndMembers({ getters, commit }) {
        const CustomerUUID = getters.current_account_uid
        const MobileProductUUID = getters.customer_current_product_uuid

        if (!CustomerUUID || !MobileProductUUID) {
            commit('SAVE_MOBILE_GROUPS_AND_MEMBERS', { mobile_groups_and_members: [] })

            return Promise.reject("No params")
        }

        return axios.get(`/v2/:spid/mobile_group_info?CustomerUUID=${CustomerUUID}&MobileProductUUID=${MobileProductUUID}`)
            .then(({ data }) => {
                if (!data) {
                    commit('SAVE_MOBILE_GROUPS_AND_MEMBERS', { mobile_groups_and_members: [] })
                    
                    return Promise.reject("No data")
                }
                
                const { MobileGroupInfos = [] } = data
                
                commit('SAVE_MOBILE_GROUPS_AND_MEMBERS', {
                    mobile_groups_and_members: MobileGroupInfos,
                    current_account_uid: CustomerUUID,
                    customer_current_product_uuid: MobileProductUUID,
                })
            })
            .catch(error => {
                commit('SAVE_MOBILE_GROUPS_AND_MEMBERS', { mobile_groups_and_members: [] })

                if (error?.response?.status == 404 && errMessage(error) == 'Object not found') {
                    return Promise.resolve([])
                }

                return Promise.reject(error)
            })
    },

    LeaveMobileGroup({ getters, dispatch }, { group_uuid } = {}) {
        const MobileProductUUID = getters.customer_current_product_uuid

        if (!group_uuid || !MobileProductUUID) return Promise.reject("No params")

        const req = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            MobileGroupUUID: group_uuid,
            MobileProductUUID,
        }

        return dispatch('api_mobilegroup/LeaveMobileGroup', req)
    },

    RemoveOtherMobileFromGroup({ getters, dispatch }, { group_uuid, customer_to_delete_uuid, mobile_product_to_delete_uuid } = {}) {
        const MobileProductUUID = getters.customer_current_product_uuid

        if (!group_uuid || !customer_to_delete_uuid || !mobile_product_to_delete_uuid || !MobileProductUUID) return Promise.reject("No params")

        const req = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            OtherCustomerUUID: customer_to_delete_uuid,
            MobileGroupUUID: group_uuid,
            OtherMobileProductUUID: mobile_product_to_delete_uuid,
            MobileProductUUID,
        }

        return dispatch('api_mobilegroup/RemoveOtherMobileFromGroup', req)
    },

    GetMobileInvites({ getters, dispatch, commit }) {
        const CustomerUUID = getters.current_account_uid
        const ProductUUID = getters.customer_current_product_uuid
        const SPID = getters.app_spid
        
        return dispatch('api_approval/GetCustomerApprovals', { SPID, CustomerUUID, ProductUUID }).then(invites => {
            commit('SAVE_MOBILE_INVITES', invites)

            return Promise.resolve(invites)
        }).catch(error => {
            console.log('GetMobileInvites error:', error)

            commit('RESET_MOBILE_INVITES')

            return Promise.resolve([])
        })
    },

    SendMobileInvite({ getters, dispatch }, { RequestedTo, ResourceUUID, Description = '', is_billing = false } = {}) {
        if (!RequestedTo) return Promise.reject("No params")

        const SPID = getters.app_spid
        const CustomerUUID = getters.current_account_uid
        const RequestedByProductUUID = getters.customer_current_product_uuid

        const req = {
            SPID,
            CustomerUUID,
            RequestedTo,
            Description,
            RequestedByProductUUID,
            ExpiresAtNanos: (new Date().getTime() + 60 * 60 * 1000) * 1000000,
            ApprovalType: is_billing ? 'MOBILEGROUP_BILLING_INVITE' : 'MOBILEGROUP_INVITE',
        }

        ResourceUUID ? req.ResourceUUID = ResourceUUID : req.ResourceDetails = 'COMPANION'

        return dispatch('api_approval/Invite', req)
    },

    ActionMobileApproval({ getters, dispatch }, { UUID, Action } = {}) {
        if (!UUID) return Promise.reject("No UUID")

        const SPID = getters.app_spid
        const CustomerUUID = getters.current_account_uid
        const ProductUUID = getters.customer_current_product_uuid

        const req = {
            SPID,
            CustomerUUID,
            ProductUUID,
            UUID,
            Action,
        }

        return dispatch('api_approval/ActionApproval', req)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}