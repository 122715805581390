/**
 * Checks if card is the same month as today
 * @param {number} card_expiry_month - card expiry month
 * @param {number} card_expiry_year - card_ expiry year
 */

export const isCardAboutToExpire = (card_expiry_month, card_expiry_year) => {
    const current_date = new Date()
    const current_year = current_date.getFullYear()
    const current_month = current_date.getMonth() + 1

    return card_expiry_month == current_month && card_expiry_year == current_year
}

/**
 * Checks if card is expired
 * @param {number} card_expiry_month - card expiry month
 * @param {number} card_expiry_year - card_ expiry year
 */

 export const isCardExpired = (card_expiry_month, card_expiry_year) => {
    const current_date = new Date()
    const current_year = current_date.getFullYear()
    const current_month = current_date.getMonth() + 1

    return card_expiry_year < current_year || ( card_expiry_year == current_year && card_expiry_month < current_month)
}
