import dynStorage from '@/utils/storage-dynamic'

// import i18n from '@/i18n';

export default {
    state: {
        // faq_section_id: null,
		is_app_header_hidden: false,
		platform: 'web',
    },

    getters: {
        // faq_section_name: (state, getters) => i18n.t('env_help_center.faq_section_name') || 'FAQs',
		is_app_header_hidden: state => state.is_app_header_hidden,
		platform: state => state.platform,
    },

    actions: {
        GetHelpCenterCategories({ getters, dispatch }) {
            const action = getters.is_app_support_service_intercom
                ? 'GetIntercomCategoriesList'
                : 'GetZendeskCategoriesList'

            return dispatch(action)
        },

        GetHelpCenterCategoryById({ dispatch }, category_id) {
            return dispatch('GetHelpCenterCategories').then(categories => {
                return categories.find( category => category.id == category_id )
            })
        },

        GetHelpCenterMenu({ getters, dispatch }) {
            const action = getters.is_app_support_service_intercom
                ? 'GetHelpCenterIntercomMenu'
                : 'GetHelpCenterZeneskMenu'

            return dispatch(action)
        },

        GetHelpCenterZeneskMenu({ dispatch }) {
            console.log('GetHelpCenterZeneskMenu')

            return Promise.all([
                dispatch('GetZendeskCategoriesList'),
                dispatch('GetZendeskSectionsList'),
                dispatch('GetZendeskArticlesList'),
            ]).then( ([categories, sections, articles]) => {
                const menu = sections.map(({category_id, id, description, name}) => ({category_id, id, description, name}))

                articles.forEach(({ body, id, label_names, title, section_id }) => {
                    const menu_section = menu.find(({id}) => id === section_id)

                    if (menu_section) {
                        if (!menu_section.articles) {
                            menu_section.articles = []
                        }

                        const article_category_id = menu_section.category_id
                        const article_category_name = categories.find(({id}) => id === article_category_id)?.name || ''

                        menu_section.articles.push({ id, category_id: article_category_id, category_name: article_category_name, section_id, label_names, title, body })
                    }
                })

                return menu
            })
        },

        GetHelpCenterIntercomMenu({ getters, dispatch }) {
            return Promise.all([
                dispatch('GetIntercomCollectionsList'),
                dispatch('GetIntercomArticlesList'),
            ]).then(()=> {
                const categories = getters.intercom_categories_list
                const sections = getters.intercom_sections_list
                const articles = getters.intercom_articles_list

                const menu = sections.map(({parent_id, id, description, name}) => ({category_id: parent_id, id, description: (description || name), name}))

                articles.forEach(({ body, id, label_names, title, parent_id: section_id }) => {
                    const menu_section = menu.find(({id}) => id == section_id)

                    if (menu_section) {
                        if (!menu_section.articles) {
                            menu_section.articles = []
                        }

                        const article_category_id = menu_section.category_id
                        const article_category_name = categories.find(({id}) => id === article_category_id)?.name || ''

                        menu_section.articles.push({ id, category_id: article_category_id, category_name: article_category_name, section_id, label_names, title, body })
                    }
                })

                return menu
            })
        },

        GetHelpCenterSectionById({ getters, dispatch }, section_id) {
            const is_intercom = getters.is_app_support_service_intercom

            const action = is_intercom
                ? 'GetIntercomSectionsList'
                : 'GetZendeskSectionsList'

            return dispatch(action).then(sections => {
                return sections.find(section => section.id == (is_intercom ? section_id : +section_id))
            })
        },

        GetHelpCenterArticleById({ getters, dispatch }, article_id) {
            const is_intercom = getters.is_app_support_service_intercom

            const action = is_intercom
                ? 'GetIntercomArticlesList'
                : 'GetZendeskArticlesList'

            return dispatch(action).then(articles => {
                return articles.find(article => article.id == (is_intercom ? article_id : +article_id))
            })
        },

        GetHelpCenterArticlesBySectionId({ getters, dispatch }, section_id) {
            const is_intercom = getters.is_app_support_service_intercom

            const action = is_intercom
                ? 'GetIntercomArticlesList'
                : 'GetZendeskArticlesList'

            return dispatch(action).then(articles => {
                return articles.filter(article => is_intercom ? article.parent_id == +section_id : article.section_id == +section_id)
            })
        },

        FindHelpCenterArticles({ getters, dispatch }, search_word) {
            if (!search_word) {
                return Promise.resolve([])
            }

            const action = getters.is_app_support_service_intercom
                ? 'FindIntercomArticles'
                : 'FindZendeskArticles'

            return dispatch(action, search_word).then(result_ids => {
                return dispatch('GetHelpCenterMenuArticlesList').then(articles => {
                    return articles.filter(({id}) => result_ids.includes(id))
                })
            })
        },

        GetHelpCenterMenuArticlesList({ dispatch }){
            return dispatch('GetHelpCenterMenu').then(menue => {
                const helpCenterMenuArticlesList = []

                if (!menue) return helpCenterMenuArticlesList

                menue.forEach(({articles}) => {
                    if (articles) {
                        articles.forEach(article => {
                            helpCenterMenuArticlesList.push(article)
                        })
                    }
                })

                return helpCenterMenuArticlesList
            })
        },

        /* GetFaqSectionId({ state, getters, dispatch, commit }) {
            if (state.faq_section_id) {
                return Promise.resolve(state.faq_section_id)
            }

            return dispatch('GetZendeskSectionsList').then( sections => {
                const faq_section_name = getters.faq_section_name

                const faq_section = sections.find( section => section.name == faq_section_name)

                if (faq_section) {
                    commit('SET_FAQ_SECTION_ID', faq_section.id)
                    return faq_section.id
                } else {
                    return null
                }
            })
        }, */

        /* GetFaqArticles({ dispatch }) {
            return dispatch('GetFaqSectionId').then( faq_section_id => {
                return dispatch('GetHelpCenterArticlesBySectionId', faq_section_id)
            })
        }, */

		SetIsAppHeaderHidden({ commit }, is_hidden) {
			commit('SET_IS_APP_HEADER_HIDDEN', is_hidden)
		},

		SetPlatform({ commit }, platform) {
			commit('SET_PLATFORM', platform)
		},
    },

    mutations: {
        /* SET_FAQ_SECTION_ID( state, faq_section_id) {
            state.faq_section_id = faq_section_id
        },  */

        SET_IS_APP_HEADER_HIDDEN(state, is_hidden) {
            state.is_app_header_hidden = is_hidden

            dynStorage.set('is_app_header_hidden', is_hidden, null, true);
        },

        SET_PLATFORM(state, platform) {
            state.platform = platform

            dynStorage.set('platform', platform, null, false);
        },
    }
}