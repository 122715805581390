let showConfirmationModalResolve = null

export default {
    state: {
        confirmation_modal: {
            show: false,
            message: null,
            has_header: true,
            title: undefined,
        },
    },
    
    getters: {
        confirmation_modal_show: state => state.confirmation_modal.show,
        confirmation_modal_message: state => state.confirmation_modal.message,
        confirmation_modal_has_header: state => state.confirmation_modal.has_header,
        confirmation_modal_title: state => state.confirmation_modal.title,
    },

    mutations: {
        showConfirmationModal(state, { confirmation_message, title, has_header = true }) {
            state.confirmation_modal = {
                has_header,
                title,
                show: true,
                message: confirmation_message,
            }
        },

        hideConfirmationModal(state, is_confirmed) {
            state.confirmation_modal = {
                show: false,
                message: null,
                has_header: true,
                title: undefined,
            }

            if (showConfirmationModalResolve) {
                showConfirmationModalResolve(is_confirmed)
                showConfirmationModalResolve = null
            }
        },
    },

    actions: {
        showConfirmationModalPromise({ commit }, { confirmation_message, title, has_header = true } = {}) {
            return new Promise(resolve => {
                commit('showConfirmationModal', { confirmation_message, title, has_header })

                showConfirmationModalResolve = resolve
            })
        },
    },
}
